/* Animation for the hero elements */
@import '../../styles/customMediaQueries.css';

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--marketplaceColor);

  /* Text positioning is done with paddings */
}

.heroHorizontalBoundary {
  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    padding: 0 36px 0 36px;
  }
}

.heroContent {
  flex: 1 1 100%;

  @media only screen and (min-width: 1367px) {
    width: 100%;
  }
}

.titleAndForm {
  composes: heroHorizontalBoundary;

  position: relative;
  z-index: 1;
}

.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;

  color: var(--matterColorLight);
  composes: animation;
  animation-delay: 0.5s;
  margin-bottom: 36px;
  text-align: center;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 70px;
  }
}

.searchForm {
  transition: all 0.4s;
  composes: animation;
  animation-delay: 0.5s;
}

.collapseTowardsTopbar {
  transform: translate3d(0, -500px, 0) scale3d(0.3, 0.3, 1);
}

.collapseTowardsHero {
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}

.callToActionArea {
  composes: heroHorizontalBoundary;

  align-self: center;
}

.heroSubTitle {
  composes: h2 from global;

  color: var(--matterColorLight);
  font-weight: var(--fontWeightRegular);
  text-align: center;

  composes: animation;
  animation-delay: 0.65s;
  margin: 0 0 50px;
  font-size: 16px;

  @media (--viewportMedium) {
    font-size: 22px;
  }
}

.heroButton {
  composes: buttonSecondary from global;

  margin: 0 auto;
  composes: animation;
  animation-delay: 0.8s;
  width: auto;
  max-width: 234px;
}

.spacer {
  height: 40px;
}

.expandTopbarOnMobile {
  display: flex;
  align-items: center;
  border: 0;
  text-align: center;
  flex-grow: 1;
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);
  box-shadow: var(--boxShadowButton);
  outline: none;
  z-index: 1;
  padding: 10px 10px 10px 24px;
  margin: 0 auto;
  width: auto;
  max-width: 320px;

  & > label {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    margin-right: 20px;

    @media (--viewportMedium) {
      font-size: 16px;
    }
  }

  & > span {
    width: 44px;
    height: 44px;
    min-width: 44px;
    min-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: var(--marketplaceColor);
    border-radius: 50%;

    & > svg {
      stroke: var(--matterColorLight);
    }
  }
}

.disciplines {
  z-index: 0;
  composes: animation;
  animation-delay: 0.5s;

  padding: 0 24px;
  margin: 30px auto 0;
  width: 100%;
  max-width: calc(1290px + 36px + 36px);
  display: flex;
  justify-content: space-between;

  @media (--viewportLarge) {
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    padding: 0;
    max-width: 1290px;
  }
}
