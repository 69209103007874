.inputContainer {
  &:focus-within + .optionsContainer {
    display: block;
  }
}

.optionsContainer {
  position: relative;
  display: none;
  width: 100%;

  &:hover,
  &:focus-within {
    display: block;
  }
}

.alwaysOpen {
  display: block;
}

.options {
  margin: 0;
  position: absolute;
  left: 0;
  top: 12px;
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius32);
  box-shadow: var(--boxShadowButton);
  border: 0;
  outline: none;
  padding: 30px;
  width: 100%;
}

.flatOptions {
  position: static;
  margin-top: 12px;
}

.list {
  margin: 0;
  padding: 0;
  width: 100%;
}

.item {
  margin: 0;
  position: relative;

  &:hover .itemBorder {
    width: 6px;
  }

  &:last-of-type label {
    margin-bottom: 0;
  }
}

.itemBorder {
  position: absolute;
  top: 0;
  left: -30px;
  bottom: 0;
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}
