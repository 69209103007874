.input {
  border-color: var(--colorAttention);
  height: 100%;

  &:disabled {
    border-color: var(--matterColorNegative);
    color: var(--matterColorNegative);
    cursor: default;
  }
}

.inputSuccess {
  border-color: var(--colorSuccess);
}

.inputError {
  border-color: var(--colorFail);
}
