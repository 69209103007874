@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  padding: 8px 18px;
  margin-top: 12px;

  min-height: 48px;

  border-radius: var(--borderRadius20);
  box-shadow: var(--boxShadowButton);

  font-weight: var(--fontWeightRegular);

  @media (--viewportMedium) {
    padding: 8px 24px;
    min-height: 50px;
  }
}

.expired {
  opacity: 0.8;
  background: var(--matterColorNegative);
}

.percentIcon {
  flex-shrink: 0;
}

.promotion {
  font-size: 16px;
  flex: auto;
}

.verifiedIcon {
  min-width: 24px;
  min-height: 24px;
}
