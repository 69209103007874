.root {
}

.fillColor {
  fill: var(--colorSuccess);
}

.fillVerified {
  fill: var(--colorSuccess);
}

.fillClient {
  fill: none;
}

.fillNotVerified {
  fill: var(--colorAttention);
}

.fillBusiness {
  fill: var(--marketplaceColorDark);
}

.fillLocal {
  fill: var(--marketplaceColorLight);
}

.fillDefault {
  fill: var(--marketplaceColor);
}
