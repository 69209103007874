@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1 1 auto;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    flex: 0 1 auto;
    padding-right: 12px;
  }
}

.expandedRoot {
  position: absolute;
  left: 0;
  right: 0;
  padding-right: 0;
  background-color: var(--matterColorLight);
  z-index: -1;
}

.searchPageExpandedRoot {
  height: 0;
  top: -12px;

  @media (--viewportMedium) {
    height: inherit;
  }
}

.expandButton {
  composes: buttonSecondary from global;
  white-space: nowrap;
  width: auto;
  position: relative;
}

.hideExpandButton {
  display: none;
}

.selectedValuesBadge {
  background-color: var(--marketplaceColor);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  position: absolute;
  right: 0;
  top: 0;
}

.expandableFields {
  position: relative;

  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);

    padding: 0;
    gap: 4px;
  }
}

.expandableFieldsInTopbar {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  transition: all 0.3s;
  transform: translate3d(0, 0, 0) scale3d(0.4, 0.4, 1);
  padding: 0 24px;

  @media (--viewportMediumMax) {
    &.fieldsExpanded {
      min-width: 320px;
      width: 70%;
    }
  }

  @media (--viewportMedium) {
    padding: 0;
  }
}

.fieldsExpandedByDefault {
  max-width: var(--topbarExpandedMaxWidth);
  width: 100%;
  display: flex;

  @media (--viewportMedium) {
    height: var(--topbarHeightExpandedDesktop);
    box-shadow: var(--boxShadowButton);
  }
}

.fieldsExpanded {
  composes: fieldsExpandedByDefault;

  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 164px, 0) scale3d(1, 1, 1);
  left: unset;
  right: unset;

  @media (--viewportMedium) {
    position: relative;
    margin: 0 24px;

    transform: translate3d(0, 100px, 0) scale3d(1, 1, 1);
  }
}

/* Location */

.location {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);
  margin-bottom: 24px;
  height: var(--topbarHeight);
  box-shadow: var(--boxShadowButton);
  outline: none;
  z-index: 1;

  @media (--viewportMedium) {
    height: auto;
    box-shadow: none;
    margin-bottom: 0;

    flex: 1;
    transition: all 0.3s;

    &:hover {
      background-color: var(--matterColorEE);
      box-shadow: var(--boxShadowButton);
    }

    &:focus-within {
      box-shadow: var(--boxShadowButton);
    }
  }
}

.locationInModal {
  box-shadow: unset;
  border-radius: 0;
  height: auto;
}

.locationLabelWrapper {
  & > label {
    margin: 0;
    padding: 20px 12px 0 24px;
  }
}

.locationAutocompleteInput {
  border: 0;
  border-radius: 0;
  padding: 0 12px 12px 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: transparent;

  &::placeholder {
    color: var(--matterColorDark);
  }
}

.locationAutocompleteInputInModal {
  padding-bottom: 6px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.locationInputWrapperInModal {
  flex-direction: column;
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  padding-top: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;

  display: flex;
  flex-direction: column;

  background-color: var(--matterColorLight);

  border-radius: var(--borderRadius32);
  box-shadow: var(--boxShadowButton);
  outline: none;

  z-index: calc(var(--zIndexPopup) + 1);

  & li {
    color: var(--colorGrey700);
    font-size: 14px;
    line-height: 24px;
  }

  @media (--viewportMedium) {
    top: 52px;
    max-height: 400px;
    overflow-y: auto;
    overscroll-behavior: contain;
  }
}

.predictionsRootInModal {
  top: 40px;
  box-shadow: unset;
  border-radius: 0;
  position: static;
  z-index: unset;
}

.invalidLocation {
  color: var(--colorFail);
}

.activitySelect {
  flex-grow: 1;
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);
  margin-bottom: 12px;
  height: var(--topbarHeight);
  box-shadow: var(--boxShadowButton);
  outline: none;
  z-index: 1;

  @media (--viewportMedium) {
    height: auto;
    box-shadow: none;
    margin-bottom: 0;

    flex: 1;
    transition: all 0.3s;

    /* &:focus-within {
      outline: none;
      flex: 2;
    } */
  }
}

.activitySelectInModal {
  box-shadow: none;
  border-radius: 0;
  height: auto;
}
.activityOptionsInModal {
  box-shadow: unset;

  padding-left: 24px;
  padding-right: 24px;

  & [class*='itemBorder'] {
    left: -24px;
  }
}

.activityOptions {
  padding-left: 0;
  padding-right: 0;

  & > ul {
    padding-left: 30px;
    padding-right: 30px;
    /* 400px - 2 * 30px padding */
    max-height: 340px;
    overflow-y: auto;
    overscroll-behavior: contain;
    /* Show shadows to indicate that element can be scrolled */
    background:
  /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background:
  /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  }
}

.activitySelectInputContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: var(--borderRadius);

  & > label {
    margin: 0;
    padding: 20px 12px 0 24px;
  }

  & > input {
    border: 0;
    border-radius: 0;
    padding: 0 12px 12px 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: transparent;

    &::placeholder {
      color: var(--matterColorDark);
    }
  }

  @media (--viewportMedium) {
    &:hover {
      background-color: var(--matterColorEE);
      box-shadow: var(--boxShadowButton);
    }

    &:focus-within {
      box-shadow: var(--boxShadowButton);
    }
  }
}

.activitySelectInputContainerInModal {
  box-shadow: var(--boxShadowButton);
  border-radius: 0;
  box-shadow: unset;
  height: var(--topbarHeight);
  border-bottom: 1px solid var(--matterColorNegative);
}

.dummyLocationSelect,
.dummyActivitySelect {
  padding: 0;
  border: 0;
  outline: none;
  text-align: left;

  composes: activitySelect;

  padding: 10px 24px 8px;

  & > label {
    margin: 0;
    padding: 0;
  }

  @media (--viewportMedium) {
    display: none;
  }
}

.dummyLocationSelectInput,
.dummyActivitySelectInput {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--matterColorDark);
}

.dummyInputWithValues {
  color: var(--matterColorDark);
}

.datesAndSubmit {
  height: var(--topbarHeight);

  position: relative;
  display: flex;
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);
  outline: none;

  @media (--viewportMedium) {
    height: auto;
    flex: 1;
    transition: all 0.3s;
  }
}

.submitButtonContainer {
  height: 60px;
  padding: 12px 12px 12px 8px;
  position: absolute;
  right: 0;
  top: auto;
  bottom: 0;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    top: 0;
    height: 100%;
    position: static;
  }
}

.submitButtonContainerInTopbar {
  @media (--viewportMediumMax) {
    position: static;
    margin-top: 12px;
    padding: 0;
  }

  @media (--viewportMedium) {
    right: 0;
  }
}

.submitButton {
  width: auto;
  height: auto;
  padding: 10px 16px;
  border-radius: var(--borderRadius);
  font-size: 14px;
}

.submitButtonInTopbar {
  @media (--viewportMediumMax) {
    width: auto;
    height: auto;
    padding: 10px 24px;
    border-radius: var(--borderRadius);
    font-size: 16px;
  }
}

.modalContent {
  padding: 60px 0;
  flex-grow: 1;
}

.modalFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: var(--boxShadowTop);
  padding: 12px 24px;
  background-color: var(--matterColorLight);
  z-index: 2;
}

.displayedDates {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: var(--matterColorDark) !important;
}

.datesPickerToggle {
  margin-bottom: 0;
}
