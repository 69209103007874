@import '../../styles/customMediaQueries.css';

.root {
  display: inline-flex;
  align-items: center;
  gap: 20px;

  padding: 16px 12px;
  border-radius: var(--borderRadius20);

  user-select: none;

  &:hover {
    box-shadow: var(--boxShadowButton);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    padding: 30px 20px;
  }
}

.label {
  composes: h3 from global;

  font-weight: var(--fontWeightBold);
  color: var(--matterColorDark);
  margin: 0;
}

.listing {
  composes: h3 from global;

  color: var(--matterColor);
  margin: 12px 0 0 0;
}

.iconWrapper {
  width: 35px;
  height: 35px;

  @media (--viewportMedium) {
    width: 50px;
    height: 50px;
  }
}
