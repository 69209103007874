@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    padding: 12px 24px;
    box-shadow: var(--boxShadowButton);
    border-radius: var(--borderRadius20);
  }
}

.title {
  margin-top: 0;
  display: flex;
  align-self: flex-start;
  font-weight: var(--fontWeightBold);
  z-index: 1;

  @media (--viewportMedium) {
    position: absolute;
    top: 12px;
    left: 24px;
    margin: 0;
  }
}

.calendar {
  width: 100%;
  height: 100%;
  min-height: 500px;
  max-height: 500px;
  position: relative;

  @media (--viewportMedium) {
    min-height: 648px;
    max-height: 648px;
  }

  & :global(.rbc-today) {
    background-color: var(--colorSuccessLight);
  }

  & :global(.rbc-label) {
    display: flex;
    justify-content: flex-end;

    @media (--viewportMedium) {
      margin-top: -6px;
    }
  }

  & :global(.rbc-off-range) {
    color: var(--matterColor);
  }

  & :global(.rbc-event-label) {
    display: none;
  }

  & :global(.rbc-event-content) {
    width: 100%;
    height: 100%;
  }

  & :global(.rbc-month-view),
  & :global(.rbc-time-view),
  & :global(.rbc-header) {
    border: 0;
  }

  & :global(.rbc-month-header) {
    display: none;
  }

  & :global(.rbc-event) {
    height: 100%;
  }

  & :global(.rbc-month-view) {
    border: 1px solid var(--matterColorF6);
  }

  & :global(.rbc-month-view),
  & :global(.rbc-time-view) {
    border-radius: var(--borderRadius20);
    overflow: hidden;
  }

  & :global(.rbc-month-row) {
    justify-content: center;
  }

  & :global(.rbc-date-cell) {
    text-align: center;
    padding-right: 0;
  }

  & :global(.rbc-time-header) {
    display: none;
  }

  & :global(.rbc-time-content) {
    border: 0 !important;

    & > * + * > * {
      border-left: var(--BigCalendar_border);
    }
  }

  & :global(.rbc-time-gutter) {
    font-size: 12px;
    font-weight: var(--fontWeightBold);

    & .calendarNoSessionDays {
      background-color: transparent;
    }

    @media (--viewportMedium) {
      font-size: 14px;
    }
  }

  & :global(.rbc-month-row) {
    &:not(:last-of-type) {
      margin-bottom: 2px;
      border-bottom: var(--BigCalendar_border);
    }

    & + :global(.rbc-month-row) {
      border-top: 0;
    }
  }

  & :global(.rbc-row) {
    height: 100%;
  }

  & :global(.rbc-row-content) {
    flex: 1 1;
    position: relative;

    & > div:first-of-type {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 5;
      pointer-events: none;
    }

    & > div:last-of-type {
      height: 100%;
    }
  }

  & :global(.rbc-row-segment) {
    height: 100%;
    padding: 0 2px 0 0;

    &:last-of-type {
      padding: 0 0 0 0.5px;
    }
  }

  & :global(.rbc-day-bg) {
    border-top: var(--BigCalendar_border);
    border-right: var(--BigCalendar_border);

    & + :global(.rbc-day-bg) {
      margin-left: 2px;
      border-left: var(--BigCalendar_border);
    }
  }

  & :global(.rbc-off-range-bg) {
    background-color: var(--matterColorF6);
  }

  & :global(.rbc-timeslot-group) {
    border-bottom: 2px solid var(--matterColorLight);
  }

  & :global(.rbc-events-container) {
    margin-right: 0 !important;
  }
}

.withLeftToolbar {
  padding-left: 24px;
}

.weekView {
  & :global(.rbc-day-slot) {
    border-left: 2px solid var(--matterColorLight);

    & :global(.rbc-time-slot) {
      border-bottom: var(--BigCalendar_border);
    }

    &:nth-child(2) {
      border-top-left-radius: var(--borderRadius20);
      border-bottom-left-radius: var(--borderRadius20);
      border-left: 0;

      & > :global(.rbc-timeslot-group) {
        &:first-child {
          border-top-left-radius: var(--borderRadius20);

          & > div {
            border-top-left-radius: var(--borderRadius20);
          }
        }

        &:nth-last-child(2) {
          border-bottom-left-radius: var(--borderRadius20);

          & > div {
            border-bottom-left-radius: var(--borderRadius20);
          }
        }
      }

      & > :global(.rbc-events-container) {
        border-left: 0;
      }
    }

    &:last-child {
      border-top-right-radius: var(--borderRadius20);
      border-bottom-right-radius: var(--borderRadius20);
    }
  }
}

.dayView {
  & :global(.rbc-day-slot) {
    border-radius: var(--borderRadius20);
    overflow-y: hidden;
  }

  & :global(.rbc-events-container) {
    border-top-left-radius: var(--borderRadius20);
    border-bottom-left-radius: var(--borderRadius20);
    border-bottom: var(--BigCalendar_border);
  }
}

.calendarOutOfBoundsDays {
  background-color: var(--matterColorF6) !important;
}

.calendarNoSessionDays {
  background-color: var(--matterColorNegative);
}

.spinnerOverlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--matterColorLightTransparent);
  border-radius: inherit;
  pointer-events: none;
}

/* //////// LEGEND ///////// */

.legend {
  display: flex;
  flex-direction: column;
  margin: 24px;
  color: var(--matterColor);
}

.status {
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}

.statusCircle {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;
  margin-right: 12px;
}

.booking {
  border-radius: 0;
}

.bookingPending {
  background-color: var(--colorAttention) !important;
  border-color: var(--colorAttentionLight) !important;
}

.bookingConfirmed {
  background-color: var(--marketplaceColor) !important;
  border-color: var(--marketplaceColor) !important;
}

.bookingBothStatesHorizontal {
  background: var(--marketplaceColor) !important;
  background: linear-gradient(
    180deg,
    rgba(255, 170, 0, 1) 50%,
    rgba(0, 155, 255, 1) 50%
  ) !important;
}

.bookingBothStatesVertical {
  background: var(--marketplaceColor) !important;
  background: linear-gradient(90deg, rgba(255, 170, 0, 1) 50%, rgba(0, 155, 255, 1) 50%) !important;
}

.error {
  color: var(--colorFail);
  margin-top: 48px;
}

.greyedOut {
  position: relative;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: rgba(0, 0, 0, 0.5);
    pointer-events: none;
  }
}
