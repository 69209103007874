@import '../../styles/customMediaQueries.css';

.root {
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  user-select: none;

  height: 100%;
  width: 100%;
  max-width: 150px;
  border-radius: var(--borderRadius20);
  color: var(--matterColorDark);

  &:hover {
    box-shadow: var(--boxShadowButton);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    max-width: 234px;
  }

  @media (--viewportLarge) {
    max-width: 320px;
  }
}

.placeNameWrapper {
  display: flex;
  align-items: center;
  flex: 1;
}

.placeName {
  composes: h4 from global;

  font-weight: var(--fontWeightBold);
  padding: 0 6px;
  text-align: center;
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: var(--borderRadius20);

  min-width: 150px;
  max-height: 150px;

  @media (--viewportMedium) {
    min-width: 234px;
    max-height: 234px;
  }

  @media (--viewportLarge) {
    min-width: initial;
    max-height: initial;
  }
}

/* An image wrapper that defines the images aspect ratio 1:1 */
.aspectWrapper {
  padding-bottom: 100%;
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--borderRadius20);
  object-fit: cover;
}
