@import '../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  color: inherit;
  position: relative;
  padding: 6px 72.8px 6px 24px;
  gap: 8px;

  @media (--viewportMedium) {
    gap: 18px;
    height: var(--topbarHeightDesktop);
    padding: 0 12px 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 24px 0 36px;
  }
}

/* logo */
.logoLink {
  flex-shrink: 0;
  height: 100%;
}

.searchPageLogoLink {
  display: none;

  @media (--viewportMedium) {
    display: inline-block;
  }
}

.logoWrapper {
  height: 100%;
  width: auto;

  @media (--viewportMedium) {
    flex: 1 0 140px;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 100%;
  display: none;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;

  @media (--viewportMedium) {
    display: inline-block;
  }
}

.mobileLogo {
  width: auto;
  height: 100%;
  max-width: 120px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopLogoOnLanding {
  display: inline-block;
  padding-top: 15px;
  color: var(--matterColorLight);
}

.hideLogo {
  display: none;
}

.search {
  composes: h4 from global;
  composes: topbarContentLabel from global;

  color: var(--matterColor);
}

/* Create listing (CTA for providers) */
.createListingLink {
  composes: topbarContentLinkHover from global;
  composes: h5 from global;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--marketplaceColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.topbarDesktopLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
}

.createListing {
  composes: topbarContentLabel from global;
}

.notificationDot {
  /* Dimensions */
  width: 14px;
  height: 14px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 22%;
  right: 18%;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.notificationDotDesktopMenu {
  composes: notificationDot;
  top: -9px;
  right: -4px;
  position: relative;
}

.logoutButton {
  outline: none;
  border: 0;
  padding: 0;
  cursor: pointer;
}

/* Profile menu */
.profileMenuLabel {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 12px 0 12px;
  position: relative;
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 14px 0;
}

.marketplaceColorAvatar {
  background-color: var(--matterColorLight);

  & > svg path {
    fill: var(--marketplaceColor);
  }

  &:hover {
    background-color: var(--matterColorLight); /* Changes background color on hover */

    & > svg path {
      fill: var(--marketplaceColorDark); /* Changes SVG path fill color on hover */
    }
  }
}

.profileMenuContent {
  padding: 10px 0;
}

/* Language and currency*/

.langCurrWrapper {
  display: flex;
  margin-left: 28px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 15%;
  left: 0px;
  height: 70%;
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  composes: h4 from global;
  position: relative;
  display: block;

  white-space: nowrap;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 20px;
  padding-right: 50px;

  /* Layout details */
  color: var(--matterColorDark);
  text-align: left;
  letter-spacing: -0.25px;
  font-weight: var(--fontWeightRegular);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.button {
  composes: buttonPrimary from global;

  width: auto;
  white-space: nowrap;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.heroButton {
  composes: buttonSecondary from global;

  width: auto;
  white-space: nowrap;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.rightSide {
  display: none;
  flex: 1 0 140px;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media (--viewportMedium) {
    display: flex;
  }
}

.currentPage {
  color: var(--marketplaceColor);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--marketplaceColor);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--marketplaceColor);
    }
  }
}

.itemGap {
  margin-top: 20px;
}

.boldItem {
  font-weight: var(--fontWeightBold);
}

.hideLink {
  display: none;
}
