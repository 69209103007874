.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.itemsRoot {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

.labelWrapper {
  display: flex;
}
