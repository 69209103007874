.authorInfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.authorInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.userType {
  margin: 0 0 8px 0;
}
