.root {
}

.label {
  display: inline;
  font-weight: var(--fontWeightRegular);

  padding-right: 10px;
  margin: 0;
}

.input {
  display: inline-block;
  width: 90px;
  padding: 0 16px;
  appearance: textfield;
  text-align: right;
  border-color: var(--colorAttention);

  &:hover {
    border-color: var(--matterColor);
    outline: none;
  }

  &:focus {
    outline: none;
  }

  /*Hide arrows from input number*/
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.inputSuccess {
  border-color: var(--colorSuccess);
}

.inputError {
  border-color: var(--colorFail);
}

.locationAutocompleteInput {
  border-color: var(--colorAttention);

  &:hover {
    border-color: var(--matterColor);
    outline: none;
  }

  &:focus {
    border-color: var(--matterColorLight);
    outline: none;
    box-shadow: var(--boxShadowButton);
  }
}
