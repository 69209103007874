@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: var(--borderRadius20);
  background: var(--matterColorLight);
  box-shadow: var(--boxShadowButton);
  transition: all 0.2s;

  &:hover {
    text-decoration: none;
    transform: scale(1.01);
    box-shadow: var(--boxShadowListingCard);
  }

  @media (--viewportMedium) {
    flex-direction: row;
    height: 220px;
  }

  @media (--viewportLarge) {
    flex-wrap: wrap;
  }
}

.greyedOut {
  filter: grayscale(100%);
  opacity: 0.7;
}

.imageWrapper {
  position: relative;
  min-width: 200px;
  min-height: 150px;
  width: 100%;

  @media (--viewportMedium) {
    flex: 1 1 250px;
  }

  @media (--viewportXLarge) {
    flex: 1 1 250px;
  }
}

.rootForImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadius20);

  @media (--viewportMedium) {
    border-radius: var(--borderRadius20) 14px 14px var(--borderRadius20);
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 55%;
  padding: 10px;
  color: var(--matterColor70);

  @media (--viewportMedium) {
    padding: 20px;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.avatar {
  flex-shrink: 0;
  height: 46px;
  width: 46px;

  background-color: var(--matterColorLight);

  & > svg path {
    fill: var(--marketplaceColor);
  }
}

.mobileAvatar {
  position: absolute;
  right: 10px;
  top: 10px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopAvatar {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}

.blackText {
  composes: h3 from global;

  font-weight: var(--fontWeightBold);
  color: var(--matterColorDark);
  margin: 0;
}

.title {
  composes: h3 from global;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  margin: 0;
  margin-bottom: 4px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.info {
  composes: h3 from global;

  color: var(--matterColor70);
  font-weight: var(--fontWeightRegular);
  margin: 0;
  /* margin-top: 12px; */

  display: flex;
  flex-direction: column;
  gap: 4px;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.authorContent {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.authorName {
  composes: h3 from global;

  color: var(--matterColor70);
  font-weight: var(--fontWeightRegular);
  margin: 0;
}

.date {
  composes: blackText;
  font-weight: var(--fontWeightSemiBold);
  align-self: flex-end;
  margin-top: 6px;
}

.type {
  composes: h3 from global;

  font-weight: var(--fontWeightRegular);
  color: var(--matterColorLight);

  border-radius: var(--borderRadius);
  padding: 6px 16px;
  margin: 0;
}

.successBg {
  background-color: var(--colorSuccess);
}

.negativeBg {
  background-color: var(--matterColorNegative);
}

.attentionBg {
  background-color: var(--colorAttention);
}

.failColor {
  color: var(--colorFail);
}

.successColor {
  color: var(--colorSuccess);
}

.marketplaceBg {
  background-color: var(--marketplaceColor);
}

.notificationLabelWrapper {
  position: absolute;
  left: 20px;
  top: -10px;
}

.notificationLabel {
  line-height: initial;
  color: var(--matterColorLight);
  background-color: var(--colorFail);

  padding: 4px 8px;
  border-radius: var(--borderRadius20);
}

.deletedListing {
  color: var(--colorFail);
}
