@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  flex: 1;

  padding: 0;
  margin: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
    margin: 0 auto;
    width: 100%;
    max-width: calc(1290px + 36px + 36px);
  }

  @media (--viewportXLarge) {
    padding: 0;
    max-width: 1290px;
  }
}

.title {
  composes: h2 from global;

  font-weight: var(--fontWeightBold);

  margin: 0 auto 20px;
  padding: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightRegular);
  }

  @media (--viewportLarge) {
    max-width: 1056px;
  }
}

.wrapper {
  justify-content: space-between;

  @media (--viewportLarge) {
    display: flex;
    justify-content: center;
    max-width: 1056px;
    margin: 0 auto;
  }

  & > div {
    flex-basis: 320px;

    @media (--viewportLarge) {
      flex-basis: calc(100% / 3);
    }
  }

  & > * {
    margin: 0;
  }
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: var(--borderRadius20);
  margin-right: 20px;
  max-width: 320px;

  & > h4 {
    margin: 0;
    font-weight: var(--fontWeightBold);
    font-size: 16px;
    margin-bottom: 8px;

    @media (--viewportMedium) {
      line-height: 28px;
    }
  }

  & > p {
    margin: 0 0 20px;
    font-size: 12px;
    line-height: 20px;
    font-weight: var(--fontWeightRegular);
    padding: 0;

    @media (--viewportSmall) {
      font-size: 14px;
    }
  }

  @media (--viewportLarge) {
    padding: 0;
    max-width: unset;
  }
}

.btnLike {
  composes: buttonDefault from global;

  margin: 0;
  width: fit-content;

  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.btnLikeMobile {
  composes: buttonDefault from global;

  margin: 0;
  width: fit-content;

  @media (--viewportLarge) {
    display: none;
  }
}
