@import '../../styles/customMediaQueries.css';

.root {
  margin-top: auto;
  margin-bottom: auto;
  height: auto;
}

.rootBottomNav {
  position: static;
}

.rootEmbedded {
  margin: 32px 0 8px 0;
}

.menuLabel {
  padding: 2px 8px;
  border-radius: var(--borderRadius);

  &:hover {
    color: var(--matterColorLight);
    background-color: var(--marketplaceColorDark);
    box-shadow: var(--boxShadowButton);
  }
}

.menuLabelBottomNav {
  padding: 6px;
  display: block;
}

.contentLabel {
  display: flex;
  align-items: center;

  padding: 6px 8px;
  white-space: nowrap;
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;
}

.contentLabelBottomNav {
  padding: 0;

  display: block;
  height: 100%;
  width: 100%;
}

.labelIcon {
  margin-right: 8px;
  vertical-align: top;
  border-radius: 50%;
}

.labelIconBottomNav {
  margin-right: 0;
  display: block;

  vertical-align: middle;
}

.menuContent {
  padding: 10px 0;
  margin-top: 10px;
}

.menuContentBottomNav {
  padding: 24px 0;
  margin-top: 0;
  bottom: var(--topbarHeight);
  width: 100vw;

  border-radius: var(--borderRadius20) var(--borderRadius20) 0 0;
  box-shadow: var(--boxShadowLightTop);

  @media (--viewportMedium) {
    bottom: var(--topbarHeightDesktop);
  }
}

.menuItem {
  composes: h4 from global;

  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 22px;

  /* Layout details */
  color: var(--matterColorDark);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover,
  &.active {
    color: var(--marketplaceColor);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.embeddedItem {
  composes: menuItem;
  padding: 8px 0px 8px 22px;
}

.menuItemIcon {
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  width: 22px;
}

.menuItemIconBottomNav {
  order: 1;
}

.menuItemText {
  padding-right: 24px;
  flex: none;
}

.menuItemTextBottomNav {
  order: 2;
  padding-right: 0;
}

.menuItemBorder {
  position: absolute;
  top: 25%;
  left: 0px;
  height: 50%;
  width: 0;
  transition: width var(--transitionStyleButton);
}

.menuItemBorderBottomNav {
  left: auto;
  right: 0;
}
