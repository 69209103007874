@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  width: 100%;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:focus:not(:hover):not(:checked) + label {
    outline: 0.5px solid var(--matterColorDark);
  }

  &:checked + label {
    background-color: var(--colorSuccess);
    color: var(--matterColorLight);

    &:hover {
      color: var(--matterColorDark);
    }
  }

  &:hover + label {
    background-color: var(--matterColorEE);
  }

  &:disabled {
    &:checked + label {
      border-radius: 0;
    }

    &:not(:checked) + label {
      background-color: var(--matterColorNegative);
      border-radius: 0;
      color: var(--matterColorLight);
    }
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin: 0;
  padding: 6px;
  font-size: 14px;
  line-height: 18px;
  height: 100%;

  color: var(--matterColorDark);
  cursor: pointer;
  font-weight: var(--fontWeightRegular);
  border-radius: var(--borderRadius20);
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    padding: 16px 8px;
  }
}
