@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  border-radius: var(--borderRadius32);
  box-shadow: var(--boxShadowButton);

  @media (--viewportMedium) {
    gap: 20px;
  }
}

.darkSkin {
  background-color: var(--colorGrey700);
}

.tab {
  flex: initial;
  text-align: center;

  @media (--viewportMedium) {
    flex: 1;
  }
}

.tabContent {
  composes: buttonPrimary from global;

  display: inline-block;

  /* Font */
  composes: marketplaceTabNavFontStyles from global;
  text-decoration: none;

  font-weight: var(--fontWeightBold);
  color: var(--matterColorDark);
  background-color: var(--matterColorLight);

  line-height: 32px;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    background-color: var(--marketplaceColorLight);
    box-shadow: var(--boxShadowButton);
  }
}

.tabContent .button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}

.selectedTabContent {
  color: var(--matterColorLight);
  background-color: var(--marketplaceColor);
  box-shadow: var(--boxShadowButton);

  &:hover {
    color: var(--matterColorLight);
    background-color: var(--marketplaceColor);
  }
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}

/* Dark skin */
.tabContentDarkSkin {
  color: var(--colorGrey300);

  &:hover {
    color: var(--colorWhite);
  }
}

.selectedTabContentDarkSkin {
  border-bottom-color: var(--colorWhite);
  color: var(--colorWhite);
}

.disabledDarkSkin {
  color: var(--colorBlack);
  opacity: 0.3;
}
