@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
  transition: all 0.2s;
  color: var(--marketplaceColor);
  background-color: var(--matterColorLight);
}

.landingPageRoot {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
}

.searchPageRoot {
  height: 0;
}

.stickyRoot {
  position: sticky;
  top: 0;
  width: 100%;

  /* shadows */
  box-shadow: var(--boxShadowLight);

  color: var(--marketplaceColor);
  background-color: var(--matterColorLight);
}

.logoutButton {
  margin: 0;
}

/* ================ Modal ================ */

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadiusMedium);

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  border-radius: var(--borderRadiusMedium);
}

.genericErrorText {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--colorWhite);

  @media (--viewportMedium) {
    margin: 0;
  }
}

.whiteLayer {
  height: var(--topbarHeight);
  transition: height 0.3s;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -2;

  @media (--viewportMedium) {
    height: var(--topbarHeightDesktop);
  }
}

.expandedWhiteLayer {
  background-color: var(--matterColorLight);
  /* '5 *' because the form inputs are the same height as topbar, + logo + submit, 48 for margins  */
  height: calc(5 * var(--topbarHeight) + 48px);
  box-shadow: var(--boxShadowButton);

  @media (--viewportMedium) {
    height: calc(var(--topbarHeightDesktop) + var(--topbarHeightExpandedDesktop) + 48px);
  }
}

.searchPageExpandedWhiteLayer {
  /* '5 *' because the form inputs are the same height as topbar + logo + submit */
  height: calc(5 * var(--topbarHeight));

  @media (--viewportMedium) {
    height: calc(var(--topbarHeightDesktop) + var(--topbarHeightExpandedDesktop) + 24px);
  }
}
