.root {
  display: flex;
  align-items: center;
}

.userTypeText {
  margin: 0 6px 0 0;
}

.icon {
  min-width: 24px;
  min-height: 24px;
}
