@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
  width: 100%;
}

.label {
  composes: buttonFilter from global;
  composes: marketplaceSearchFilterLabelFontStyles from global;
}

.labelSelected {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  &:hover {
    background-color: var(--marketplaceColorDark);
    border: 1px solid var(--marketplaceColorDark);
  }
}

.labelText {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.field {
  composes: h4 from global;

  margin: 0;
  padding: 1px 0 13px 0;
  border: none;
}

.fieldPlain {
  composes: h4 from global;

  margin: 0;
  padding: 0;
  border: none;

  @media (--viewportMedium) {
    padding: 16px 0 30px 20px;
  }
}

.descriptionClassName {
  composes: h5 from global;

  font-weight: var(--fontWeightRegular);
  color: var(--colorPrimaryBlack);
}

.popupSize {
  max-height: 320px;
  overflow-y: auto;
  padding: 16px 30px 0px 30px;
}

.numberIncrementerInput {
  padding-bottom: 8px;
}

.fieldNumberIncrementerInput {
  width: 100%;

  & label {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
  }
}
