@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.listingsContent {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  flex: 1;

  padding: 0;
  margin: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
    margin: 0 auto;
    width: 100%;
    max-width: calc(1290px + 36px + 36px);
  }

  @media (--viewportXLarge) {
    padding: 0;
    max-width: 1290px;
  }
}

.title {
  composes: h2 from global;

  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  padding: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightRegular);
    margin-bottom: 12px;
  }

  @media (--viewportLarge) {
    max-width: 1056px;
  }
}

.carousel {
  justify-content: space-between;

  @media (--viewportLarge) {
    display: flex;
  }
}

.carouselContent {
  @media (--viewportLarge) {
    padding: 4px;
    overflow-x: hidden;

    flex-basis: 1090px;
    flex-grow: 0;
  }
}

.listingCard {
  margin-right: 20px;

  @media (--viewportLarge) {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.buttonWrapper {
  width: 100%;
  margin: 0 auto;

  @media (--viewportMedium) {
    max-width: 1056px;
  }
}

.primaryButton {
  composes: buttonPrimary from global;

  margin: 40px auto 0 auto;

  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;

  @media (--viewportMedium) {
    margin: 60px 0 0 auto;
  }
}
