@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
}

.reviewItem {
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 39px;
  }
}

.review {
  display: flex;
  flex-direction: column;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;
}

.reviewContent {
  composes: h4 from global;

  font-size: 14px;
  transition: height 0.3s;
  margin-bottom: 0;
  color: var(--matterColor);
  white-space: pre-line;
  word-break: break-word;
}

.ellipsedReviewContent {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.collapsed {
  -webkit-line-clamp: initial;
}

.reviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 14px;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    align-items: normal;
  }
}

.userInfo {
  display: flex;
}

.avatar {
  flex-shrink: 0;
  margin-right: 12px;
  width: 50px;
  height: 50px;

  background-color: var(--matterColorLight);

  & > svg path {
    fill: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    margin-right: 22px;
  }
}

.authorName {
  font-weight: var(--fontWeightBold);
  color: var(--matterColorDark);
}

.reviewDate {
  font-size: 14px;
  line-height: 22px;
}

.reviewInfo {
  composes: h5 from global;

  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.desktopReviewRatingWrapper {
  display: flex;
  align-items: flex-start;
  gap: 6px;
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: flex;
  white-space: nowrap;
  gap: 2px;

  margin-top: 2px;

  & > *:not(svg) {
    display: none;
  }

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.reviewRatingStar {
  height: auto;
  width: 20px;
  margin-right: 0;
}

.continueReadingLabel {
  border: 0;
  padding: 4px;
  margin-left: -4px;
  cursor: pointer;
  font-weight: var(--fontWeightBold);
  font-size: 14px;
  line-height: 24px;

  margin-right: auto;

  &:hover {
    text-decoration: underline;
  }
}

.noDisplay {
  visibility: hidden;
}
