@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  border-radius: var(--borderRadius20);
  background: var(--matterColorLight);
  transition: all 0.2s;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    flex-direction: row;

    &:hover {
      transform: scale(1.01);
      box-shadow: var(--boxShadowListingCard);
    }
  }

  @media (--viewportLarge) {
    flex-wrap: wrap;
  }
}

.greyedOut {
  filter: grayscale(100%);
  opacity: 0.7;
}

.imageWrapper {
  position: relative;
  min-width: 150px;
  min-height: 150px;
  width: 100%;

  @media (--viewportMedium) {
    flex: 0.1 0.1 150px;
  }

  @media (--viewportXLarge) {
    flex: 0.1 0.1 150px;
  }
}

.rootForImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadius20);
}

.heartButton {
  all: unset;
  position: absolute;
  top: 16px;
  right: 16px;

  &:focus,
  &:hover,
  &:disabled {
    background: transparent;
    box-shadow: none;
  }
}

/* Right section */

.mainContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 55%;
  padding: 10px;
  color: var(--matterColor70);

  @media (--viewportMedium) {
    margin: 10px 20px;
  }
}

.emphasisedText {
  composes: h3 from global;

  margin: 0;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);
}

.title {
  composes: h3 from global;

  margin: 0;
}

.info {
  composes: h3 from global;

  color: var(--matterColor70);
  font-weight: var(--fontWeightRegular);
  margin: 0;

  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
}

.infoSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.author {
  display: flex;
  gap: 8px;
}

.type {
  border-radius: var(--borderRadius);
  background: var(--matterColorNegative);
  color: var(--matterColorLight);
  padding: 4px 8px;
}
.greenColor {
  background: var(--darkGreenColor);
}
.lightGreenColor {
  background: var(--lightGreenColor);
}
.limeColor {
  background: var(--limeGreenColor);
}

.priceAndRating {
  composes: h3 from global;

  display: flex;
  justify-content: space-between;
  font-weight: var(--fontWeightBold);
  color: var(--matterColorDark);
  margin: 0;
  font-weight: var(--fontWeightSemiBold);
}
