@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  margin-top: 24px;
}

.submitButton {
  margin: auto auto 0 auto;

  @media (--viewportMedium) {
    min-width: 200px;
    width: auto;
  }
}
