@import '../../styles/customMediaQueries.css';

.root {
  margin: 0 12px;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.fieldset {
  margin: 0;
  padding: 12px;
  border: none;
  min-width: 312px;
}

.selectedSessions {
  & span,
  & li::before {
    color: var(--matterColorDark);
  }

  & > li {
    margin: 0;
    line-height: 24px;

    & > span {
      font-size: 12px;
      line-height: 24px;
    }
  }

  @media (--viewportSmall) {
    & > li {
      & > span {
        font-size: 14px;
      }
    }
  }

  @media (--viewportMedium) {
    & > li {
      margin: 0;
      line-height: 32px;

      & > span {
        line-height: 32px;
      }
    }
  }
}

.sessionHeaders {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  margin-bottom: 12px;
}

.dayOfWeek {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--matterColor4A);
}

.daySize {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}

.sessionDay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hiddenDay {
  composes: daySize;

  opacity: 0;
  visibility: hidden;
}

.headerDay {
  composes: daySize;

  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  padding: 6px;
  border-radius: 50%;
  margin: 0;
  cursor: pointer;
  font-weight: var(--fontWeightBold);

  border: 1px solid var(--matterColorLight);

  &:hover {
    border: 1px solid var(--marketplaceColor);
  }
}

.headerDaySelected {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  border: 1px solid var(--marketplaceColor);
}

.headerDayDisabled {
  color: var(--matterColorNegative);
  text-decoration: line-through;
  cursor: default;

  &:hover {
    border: 0;
  }
}

.rowList {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.item {
  display: inline-flex;
  flex-grow: 1;
  box-shadow: var(--boxShadowButton);
  border-radius: var(--borderRadius20);
  padding: 0;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}

.noDatesSelected {
  text-align: center;
}

.sessionPickerButton {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  border: 0;
  border-radius: var(--borderRadius);

  padding: 10px 12px 4px 24px;

  border: 1px solid var(--matterColorEE);

  height: 100%;
  width: 100%;
  cursor: pointer;
  text-align: left;

  & > label {
    margin: 0;
    padding: 0;
  }

  & > span {
    color: var(--matterColorCA);
    font-size: 12px;
  }

  &:hover {
    background-color: var(--matterColorEE);
    box-shadow: var(--boxShadowButton);
  }

  @media (--viewportSmall) {
    & > span {
      font-size: 14px;
    }
  }

  @media (--viewportMedium) {
    /* 86px = submit width + paddings + spacing (12px)*/
    padding: 16px 12px 8px 24px;

    & > label {
      font-size: 14px;
    }
  }
}

.filterPopup {
  margin-top: 12px;
  margin-bottom: 24px;
}

.popupOpenClassName {
  width: 100%;
}

.filterPopupClickHandler {
  display: flex;
}

.sessionsDiscount {
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  color: var(--marketplaceColor);
  margin-top: 8px;

  & > div {
    line-height: 22px;
  }
}

.sessionsDiscountPercent {
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
  border-radius: var(--borderRadius20);
  padding: 6px 10px;
  flex: 0 1 54px;
  text-align: center;
  line-height: 18px;
}

.selectorFooter {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 12px;
}

.clearButton,
.cancelButton,
.submitButton {
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;
}

.clearButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0 auto 0 0;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.cancelButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.submitButton {
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}
