@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--borderRadius32);
  background-color: var(--matterColorLight);

  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.prev,
.next {
  display: block;

  /* Dimensions */
  padding: 12px 0;

  /* Reset <a> tag sizing */
  line-height: 0;
  font-size: 0;

  @media (--viewportLarge) {
    padding: 26px 0;
  }
}

.prev {
  /* Minimum touch size 44px (svg width + padding) */
  padding-right: 33px;
}
.next {
  /* Minimum touch size 44px (svg width + padding) */
  padding-left: 33px;
}

.arrowIcon {
  /* Color for svg icons */
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);

  &:hover {
    fill: var(--marketplaceColorDark);
    stroke: var(--marketplaceColorDark);
  }
}

.disabled,
.disabled:hover {
  fill: var(--colorGrey300);
  stroke: var(--colorGrey300);
}

.pageNumberList {
  display: flex;
  justify-content: space-between;
}

/**
 * Using 'justify-content: space-between;' we can deal with very narrow mobile screens.
 * However, since the length of pageNumberList can vary up to 7,
 * we need to keep track of how much space is allocated for the list
 * Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
 */
.pageNumberList1Items {
  flex-basis: 30px;
}

.pageNumberList2Items {
  flex-basis: 60px;
}

.pageNumberList3Items {
  flex-basis: 100px;
}

.pageNumberList4Items {
  flex-basis: 160px;
}

.pageNumberList5Items {
  flex-basis: 200px;
}

.pageNumberList6Items {
  flex-basis: 250px;
}

.pageNumberList7Items {
  flex-basis: 275px;
}

.toPageLink {
  composes: h3 from global;

  color: var(--matterColorDark);
  margin: 0;

  padding: 12px 6px;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportLarge) {
    padding: 23px 6px;
  }
}

.currentPage {
  composes: h3 from global;

  color: var(--marketplaceColor);
  margin: 0;

  padding: 12px 8px 9px 8px;
  border-bottom: 3px solid var(--marketplaceColor);

  @media (--viewportLarge) {
    padding: 23px 8px 20px 8px;
  }
}

.paginationGap {
  /* Dimensions */
  padding: 12px 6px;

  /* Color for svg icons */
  color: var(--colorGrey700);

  /* Ellipsis is raised from baseline */
  line-height: 13px;

  @media (--viewportLarge) {
    padding: 23px 6px;
  }
}
