@import '../../styles/customMediaQueries.css';

.toolbar {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  font-size: 12px;

  @media (--viewportSmall) {
    font-size: 14px;
  }
}

.viewButtonGroup {
  display: flex;
  align-self: flex-start;
  border-radius: var(--borderRadius20);
  box-shadow: var(--boxShadowButton);
  margin-bottom: 24px;

  @media (--viewportMedium) {
    align-self: center;
  }
}

.viewButton {
  border: 0;
  outline: none;
  border-radius: var(--borderRadius20);
  box-shadow: none;

  &:not(:last-of-type) {
    margin-right: 2px;
  }

  @media (--viewportMedium) {
    padding: 12px 24px;
    min-width: 120px;
  }
}

.viewButtonActive {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  box-shadow: var(--boxShadowButton);
}

.paddingLeft {
  /* Week/Day view has the time column at the left
    so we need to move the headers by that amount */
  padding-left: 40px;

  @media (--viewportMedium) {
    padding-left: 46px;
  }
}

.selectedMonth {
  composes: paddingLeft;

  display: block;
  font-weight: var(--fontWeightBold);
  margin-top: 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.headers {
  composes: paddingLeft;

  display: flex;
  align-items: center;
  position: relative;
  gap: 6px;
  width: 100%;
  margin-bottom: 12px;
  font-weight: var(--fontWeightBold);
  line-height: 16px;
  padding-right: 16px;
}

.headersWeek {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.headersMonth {
  composes: headersWeek;

  padding-left: 0;
  padding-right: 0;
}

.navButton {
  border: 0;
  outline: none;
  padding: 6px;
  color: var(--marketplaceColor);
  cursor: pointer;
}

.absoluteNavButton {
  position: absolute;
}

.prevNavButton {
  composes: absoluteNavButton;

  left: -24px;
}

.nextNavButton {
  composes: absoluteNavButton;

  right: -12px;
}

.staticNavButton {
  position: static;
}

.header {
  line-height: 18px;
}

.desktopHeader {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.mobileHeader {
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (--viewportMedium) {
    display: none;
  }
}

.verticalSideToolbar {
  position: absolute;
  left: -24px;
  bottom: 0;
  transform-origin: right;
  transform: translate3d(-100%, 50%, 0) rotate3d(0, 0, 1, -90deg);
}

.verticalButtons {
  display: flex;
  align-items: center;

  & > span {
    min-width: 72px;
    text-align: center;
    font-weight: var(--fontWeightBold);
    margin: 0 6px;
  }
}
