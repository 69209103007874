.root {
  display: block;

  & > label {
    display: inline-block;
  }
}

.inputContainer {
  composes: marketplaceSelectStyles from global;

  color: var(--matterColorDark);
  border-color: var(--colorAttention);
  padding-right: 20px;

  text-align: left;

  &:focus-within {
    border-color: var(--matterColorLight);
    outline: none;
    box-shadow: var(--boxShadowButton);
  }

  &:disabled {
    border-color: var(--matterColorNegative);
    color: var(--matterColorNegative);
    cursor: default;
  }
}

.selectSuccess {
  color: var(--matterColorDark);
  border-color: var(--colorSuccess);
}

.selectError {
  border-color: var(--colorFail);
}

.optionsContainer {
  position: relative;
  display: none;
  width: 100%;
}

.options {
  position: absolute;
  left: 0;
  top: 12px;
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius30);
  box-shadow: var(--boxShadowButton);
  border: 0;
  outline: none;
  padding: 18px;
  width: 100%;
  z-index: 1;
}

.optionsShown {
  display: block;
}

.flatOptions {
  position: static;
  margin-top: 12px;
}

.list {
  margin: 0;
  padding: 0;
  width: 100%;
}

.item {
  margin: 0;
  position: relative;

  &:hover .itemBorder {
    width: 6px;
  }

  &:last-of-type label {
    margin-bottom: 0;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: var(--fontWeightBold);

  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.arrowButton {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  padding: 0;

  & svg {
    fill: var(--marketplaceColor);
    stroke: var(--marketplaceColor);
  }

  &:hover {
    background-color: var(--matterColorEE);
    cursor: pointer;
  }

  &:disabled {
    color: var(--matterColorEE);
    background-color: none;

    &:hover {
      background-color: transparent;
      cursor: default;
    }

    & svg {
      fill: var(--matterColorNegative);
      stroke: var(--matterColorNegative);
    }
  }
}

.input {
  border: none;
  border-radius: 0;
  width: 40px;
  padding: 0;
  text-align: center;
}
