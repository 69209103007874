.root {
  line-height: 20px;

  & > ul {
    margin: 12px 0;
    list-style-type: disc;

    & > li {
      margin-left: 24px;
      line-height: 20px;

      &:not(:last-of-type) {
        margin-bottom: 6px;
      }
    }
  }
}
