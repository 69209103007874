.root {
  position: relative;
  width: 100%;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:focus:not(:hover):not(:checked) + label {
    outline: 0.5px solid var(--matterColorDark);
  }

  &:checked + label {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);

    & .price {
      color: var(--matterColorLight);
    }

    &:hover {
      color: var(--matterColorDark);

      & .price {
        color: var(--matterColorDark);
      }
    }
  }

  &:hover + label {
    background-color: var(--matterColorEE);

    & .checkmark {
      stroke: var(--matterColorDark);
    }
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  margin: 0;
  padding: 16px;
  font-size: 14px;
  line-height: 18px;
  height: 100%;
  min-height: 62px;

  color: var(--matterColorDark);
  cursor: pointer;
  font-weight: var(--fontWeightRegular);
  border-radius: var(--borderRadius20);
  transition: var(--transitionStyleButton);
}

.price {
  color: var(--marketplaceColor);
}

.rightSide {
  flex: 0 1 54px;
}

.savings {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  border-radius: var(--borderRadius20);
  padding: 6px 10px;
}

.checkmark {
  stroke: var(--matterColorLight);
  width: 22px;
  height: 22px;
}
