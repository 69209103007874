@import '../../styles/customMediaQueries.css';

.root {
}

.verificiationBox {
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-bottom: 24px;

  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.verificiationBoxTextWrapper {
  flex: 1;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    margin-bottom: 0px;
  }
}

.verificationBoxTitle {
  composes: h4 from global;

  display: flex;
  font-weight: var(--fontWeightSemiBold);

  align-self: center;

  width: 100%;
  margin-top: 0;
  margin-bottom: 4px;
}

.title {
  margin: 0;
}

.verificationBoxText {
  composes: h5 from global;
  font-weight: var(--fontWeightRegular);
  margin-top: 0;
  margin-bottom: 0;
}

.getVerifiedButton {
  composes: buttonSecondary from global;

  width: auto;
  margin-top: 30px;
}

/* Verification required box */
.verficiationNeededBox {
  padding: 20px;
  margin-top: 12px;
  border: 1px solid var(--colorAttention);
  background: var(--colorAttentionLight);
}

/* Verification error box */
.verficiationErrorBox {
  padding: 20px;
  margin-top: 12px;
  background: var(--colorFailLight);
  border: 1px solid var(--colorFail);
}

/* Verification success box */
.verficiationSuccessBox {
}

.verificationBoxSuccessTextWrapper {
  margin-bottom: 0;
}

.verificationSuccessTitle {
  color: var(--matterColor);
}

.editVerificationButton {
  composes: buttonSecondary from global;

  color: var(--colorSuccess);
  width: auto;
  min-width: 150px;
  margin: 0;
  margin-top: 30px;

  &:hover {
    background-color: var(--colorSuccess);
  }

  &:focus {
    background-color: var(--matterColorLight);
  }
}

.icon {
  margin-left: 6px;
}

.spinner {
  width: 24px;
  height: 24px;
  stroke: var(--colorSuccess);
  stroke-width: 3px;
}
