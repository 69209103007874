@import '../../styles/customMediaQueries.css';

.activityContainer {
  border-radius: var(--borderRadius20);
  box-shadow: var(--boxShadowButton);
  padding: 20px;
  padding-right: 16px;
  margin-bottom: 16px;
  background-color: var(--matterColorLight);
}

.activityType {
  display: flex;
  align-items: center;
  gap: 12px;

  font-weight: var(--fontWeightBold);
  line-height: initial;

  margin-bottom: 18px;
}

.leftSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 0;
  }
}

.disciplineAbilityWrapper,
.disciplineNameWrapper {
  display: flex;
  align-items: center;
  flex: 50%;
  gap: 12px;

  line-height: initial;
}

.disciplineWrapper {
  display: flex;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.disciplineButtonsWrapper {
  display: flex;
  gap: 6px;
}

.certificateIconWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;

  & > svg {
    width: 100%;
    height: 100%;
  }
}

.openModal {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 35px;
  height: 35px;

  border: 0;
  border-radius: var(--borderRadius20);

  outline: none;
  cursor: pointer;

  &:hover:not(:disabled) {
    background-color: var(--matterColorEE);
  }
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.addButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  padding: 8px 24px;
  margin-top: 12px;

  min-height: 48px;

  border-radius: var(--borderRadius20);
  box-shadow: var(--boxShadowButton);

  font-weight: var(--fontWeightRegular);

  &:hover,
  &:focus {
    color: var(--marketplaceColor);
    background-color: var(--matterColorEE);
  }

  @media (--viewportMedium) {
    min-height: 50px;
  }
}

.addButtonPlus {
  font-size: 38px;
}
