@import '../../styles/customMediaQueries.css';

.root {
  padding: 12px;
  margin: 0;
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius20);
  box-shadow: var(--boxShadowButton);
  border: 0;
}

.bookingLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 10px;
  border-radius: var(--borderRadius20);
  color: var(--matterColorDark);

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: var(--marketplaceColorLight);
  }
}

.bookingRequested {
  opacity: 0.5;
}

.bookingCancelled {
  color: var(--colorFail);
}

.customerAvatar {
  margin-right: 8px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.customerName {
  margin-right: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 60px;

  @media (--viewportSmall) {
    max-width: 158px;
  }
}

.seats {
  margin-left: 12px;
}
