@import '../../styles/customMediaQueries.css';

.root {
}

.extended {
  position: relative;
  width: calc(100% + 15vw);
  max-width: 100vw;
  margin-left: -7.5vw;
}

.wrapper {
  flex: 1;
  overflow: hidden;

  @media (--viewportLarge) {
    padding: 4px;
  }
}

.items {
  padding: 4px 4px 8px;
  margin-bottom: -8px;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.carousel {
  display: flex;
  position: relative;

  @media (--viewportLarge) {
    transform: translate3d(calc(-1 * var(--column-size)), 0, 0);
  }
}

.carousel4 {
  @media (--viewportLarge) {
    transform: translate3d(calc(-1 * var(--column-size)), 0, 0);
  }
}

.isReversing {
  @media (--viewportLarge) {
    transform: translate3d(var(--column-size), 0, 0);
  }
}

.isReversing4 {
  @media (--viewportLarge) {
    transform: translate3d(var(--column-size), 0, 0);
  }
}

.isSet {
  @media (--viewportLarge) {
    transform: none;
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  }
}

.item {
  flex-shrink: 0;

  @media (--viewportLarge) {
    flex-basis: var(--column-size);
    order: 2;

    display: flex;
    justify-content: center;
  }
}

.item4 {
  flex-shrink: 0;

  @media (--viewportLarge) {
    flex-basis: var(--column-size);
    order: 2;
  }
}

.isRef {
  @media (--viewportLarge) {
    order: 1;
  }
}

.buttonWrapper {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
  }
}

.arrowButton {
  cursor: pointer;
  border: none;
  height: auto;
}

.iconArrow {
  fill: black;
  stroke: black;
  width: auto;
  height: 40px;
  width: 40px;
  padding: 10px;
  background: var(--matterColorLight);
  border-radius: 50%;
}

.marginAuto {
  margin: auto;
}

.extendedArrowLeft {
  position: absolute;
  top: 25%;
  left: 15%;
  z-index: 1;
}

.extendedArrowRight {
  position: absolute;
  top: 25%;
  right: 15%;
  z-index: 1;
}

.disableCarousel {
  @media (--viewportLarge) {
    transform: none;
    left: 0;
  }
}
