.title {
  composes: marketplaceModalTitleStyles from global;
}

.messages {
  composes: marketplaceModalParagraphStyles from global;

  color: var(--matterColor);

  &:last-of-type {
    margin-bottom: 32px;
  }
}

.error {
  color: var(--colorAttention);
}

.buttons {
  margin-top: auto;
}

.button {
  composes: buttonSecondary from global;

  margin: 12px 0;
  color: var(--matterColorDark);
}
