.root {
  display: block;
}

.label {
  display: flex;
}

.inputContainer {
  composes: marketplaceSelectStyles from global;

  color: var(--matterColorAnti);
  border-color: var(--colorAttention);
  padding-right: 20px;

  text-align: left;

  &:focus-within {
    border-color: var(--matterColorLight);
    outline: none;
    box-shadow: var(--boxShadowButton);
  }

  &:disabled {
    border-color: var(--matterColorNegative);
    color: var(--matterColorNegative);
    cursor: default;
  }
}

.optionsContainer {
  position: relative;
  width: 100%;

  &:hover,
  &:focus,
  &:focus-within {
    outline: none;
  }
}

.options {
  position: absolute;
  left: 0;
  top: 12px;
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius30);
  box-shadow: var(--boxShadowButton);
  border: 0;
  outline: none;
  padding: 28px 18px;
  width: 100%;
  z-index: 1;
}

.flatOptions {
  position: static;
  margin-top: 12px;
}

.list {
  margin: 0;
  padding: 0;
  width: 100%;
}

.item {
  margin: 0;
  position: relative;

  &:hover .itemBorder {
    width: 6px;
  }

  &:last-of-type label {
    margin-bottom: 0;
  }
}

.selectSuccess {
  color: var(--matterColorDark);
  border-color: var(--colorSuccess);
}

.selectError {
  border-color: var(--colorFail);
}
