.root {
  position: absolute;
  z-index: var(--zIndexPopup);

  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowButton);
  border-radius: var(--borderRadius20);
  transition: var(--transitionStyleButton);

  padding-top: 20px;
}

.isClosed {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.isOpen {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0;
}
