@import '../../../styles/customMediaQueries.css';

.root {
}

.submitContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 12px;
}

.submitButton {
  align-self: flex-start;
  margin-left: auto;
  padding: 0;
  min-width: 150px;
  width: auto;
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);
  margin-top: 24px;
}
