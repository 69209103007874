@import '../../styles/customMediaQueries.css';

.root {
  width: auto;
  align-self: flex-start;
  color: var(--matterColorDark);

  &:hover {
    color: var(--matterColorDark);
    background-color: var(--marketplaceColorLight);
  }

  @media (--viewportMedium) {
    min-width: 230px;
  }
}

.noDisplay {
  display: none;
}
