@import '../../../styles/customMediaQueries.css';

.root {
  /* Clearfix */
  composes: clearfix from global;
}

.item {
  margin-bottom: 16px;

  /* Clearfix */
  composes: clearfix from global;

  &:last-of-type {
    margin-bottom: 0;
  }
}

/* ---------------------- Message ---------------------- */

.message {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  max-width: 400px;
  float: left;
}

.ownMessage {
  float: right;
}

.messagecontentWrapper {
  composes: clearfix from global;
}

.messageContent {
  composes: marketplaceMessageFontStyles from global;

  margin: 0;
  padding: 8px 14px;
  border-radius: var(--borderRadius20);
  background-color: var(--matterColorLight);
  float: left;
}

.ownMessageContent {
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
  float: right;
}

.messageDate {
  composes: marketplaceMessageDateFontStyles from global;

  color: var(--matterColorAnti);

  margin: 6px 0 0 0;
}

.ownMessageDate {
  text-align: right;

  @media (--viewportMedium) {
    margin: 0;
  }
}

/* ---------------------- Transition ---------------------- */

.transition {
  display: flex;
  flex-direction: row;
  padding: 3px 0 2px 0;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.transitionContent {
  composes: marketplaceTxTransitionFontStyles from global;

  font-weight: var(--fontWeightBold);
  margin: 0;
}

.transitionDate {
  composes: marketplaceMessageDateFontStyles from global;
  color: var(--colorGrey300);
  margin: 7px 0 0 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.bullet {
  margin-right: 6px;
}

.reviewContent {
  composes: h4 from global;
  font-style: italic;
  white-space: pre-line;
  margin: 4px 0 8px 0;

  @media (--viewportMedium) {
    max-width: 400px;
  }
}

.reviewStar {
  width: 16px;
  height: 14px;
  margin-right: 2px;
  font-styles: initial;
}

.showOlderWrapper {
  text-align: center;
  margin-bottom: 2px;
}
