@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 15px 24px calc(var(--topbarHeight) + 28px) 24px;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    padding: 0 24px calc(var(--topbarHeightDesktop) + 40px) 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 10px 0;
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 10px;
  }

  @media (--viewportLarge) {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;
  padding: 0;

  fill: var(--matterColor);

  & > svg {
    &:not(&:hover) {
      fill: var(--colorGrey700);
    }
  }
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.linksWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (--viewportLarge) {
    gap: 40px;
  }
}

.logoWrapper {
  margin-top: 0px;
  margin-right: 10px;

  @media (--viewportMedium) {
    margin-top: 10px;
    flex-basis: 160px;
    flex-shrink: 3;
  }
}

.logoLink {
  display: inline-block;
  padding: 10px;
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: auto;
  height: 60px;
}

.partnerLogo {
  composes: logoLink;
  order: -1;

  padding-left: 0;
  margin-left: -13px;

  & > svg {
    width: 100px;
  }

  @media (--viewportMedium) {
    order: initial;
  }
}

.crionAsPartner {
  color: #009bff;
}

.organizationInfo {
  display: flex;
  align-items: flex-start;

  flex-basis: 100%;

  @media screen and (min-width: 500px) {
    flex-basis: calc(50% - 20px);
  }

  @media (--viewportMedium) {
    align-items: center;
    flex-basis: 180px;
  }
}

.organizationDescription,
.organizationCopyright {
  composes: marketplaceTinyFontStyles from global;
}

.organizationDescription {
  color: var(--matterColor4A);
}
.organizationCopyright {
  margin: 0;
}

.infoWrapper {
  flex-basis: 200px;
}

.copyrightLink {
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  flex-basis: 100%;

  @media screen and (min-width: 500px) {
    flex-basis: calc(50% - 20px);
  }

  @media (--viewportMedium) {
    padding-top: 2px;
    flex-basis: auto;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  font-weight: var(--fontWeightRegular);
  letter-spacing: 0px;
  line-height: 24px;
}

.link {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor4A);
  transition: var(--transitionStyleButton);

  text-align: left;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.buttonAsLink {
  composes: link;
  cursor: pointer;
  border: none;
  margin: 4px 0;
  padding: 0;
}

/* Desktop and Mobile: searches - first column */
.searches {
  flex-basis: 100%;

  @media screen and (min-width: 500px) {
    flex-basis: calc(50% - 20px);
  }

  @media (--viewportMedium) {
    margin-left: 40px;
    padding-top: 2px;
    flex-basis: auto;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-basis: 100%;

  @media screen and (min-width: 500px) {
    flex-basis: calc(50% - 20px);
  }

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    flex-basis: auto;
  }
}

.legalMatters {
}

.tosAndPrivacy {
  margin: 0px;

  & li {
    line-height: 24px;
  }
}

.legalLink,
.privacy,
.terms {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;
}

.installAppWrapper {
  display: flex;
  justify-content: center;
  background-color: var(--colorWhite);
  border-top: 1px solid var(--colorGrey100);
}

.installApp {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: column;
  gap: 4px;
  text-align: center;

  & div[class*='installAppTitle'] {
    margin-right: 0;
  }

  @media (--viewportMedium) {
    max-width: var(--contentMaxWidth);
    padding-left: 0;
    padding-right: 0;
  }
}
