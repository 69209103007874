.root {
  composes: marketplaceModalCloseStyles from global;

  z-index: 1;

  &:enabled:hover {
    color: var(--matterColorDark);
  }
  &:enabled:active {
    color: var(--matterColorDark);
  }
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;

  &:hover {
    color: var(--matterColorDark);
  }
}
