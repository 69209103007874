@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBaseStyles from global;
  min-height: 300px;
}

/* Icon of the modal */
.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;

  color: var(--matterColor);
}

.modalBottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.modalButton {
  composes: marketPlaceModalButton from global;
  max-width: 100%;
}

.blur {
  backdrop-filter: blur(2px);

  display: flex;

  /* Additional styles for the modal window, dimming the background and positioning the modal */
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @media (--viewportMedium) {
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: none;
  }
}
