.root {
  background-color: transparent;
  color: var(--matterColorDark);

  font-weight: var(--fontWeightRegular);
  padding: 6px 12px;

  &:focus {
    background-color: transparent;
    box-shadow: none;
  }

  &:hover {
    background-color: var(--marketplaceColorLight);
    box-shadow: none;
  }

  &:disabled {
    color: var(--matterColorDark);
  }
}

.smallButton {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 40px;

  background-color: var(--matterColorLight);
  color: var(--matterColorDark);
  padding: 6px;

  &:focus {
    background-color: var(--matterColorLight);
    box-shadow: none;
  }

  &:hover {
    background-color: var(--marketplaceColorLight);
    box-shadow: none;
  }

  &:disabled {
    color: var(--matterColorDark);
  }

  & svg {
    width: 30px;
    height: 30px;
  }
}

.icon {
  margin-left: 12px;
}

.fillSelected {
  fill: var(--marketplaceColor);

  &:hover {
    fill: var(--marketplaceColor);
  }
}

.smallIcon {
  margin: 0;
}
