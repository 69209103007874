@import '../../styles/customMediaQueries.css';

.root {
}

.input {
  display: none;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: var(--fontWeightRegular);
  color: var(--marketplaceColor);
  cursor: pointer;

  min-height: 48px;

  padding: 8px 24px;
  margin-top: 12px;

  border-radius: var(--borderRadius20);
  box-shadow: var(--boxShadowButton);

  &:hover,
  &:focus {
    background-color: var(--matterColorEE);
  }

  @media (--viewportMedium) {
    min-height: 50px;
  }
}

.plus {
  font-size: 38px;
}
