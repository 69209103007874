@import '../../styles/customMediaQueries.css';

.modalTitle {
  margin-top: 2px;

  @media (--viewportMedium) {
    margin-top: -6px;
  }
}

.modalContainer {
  composes: marketplaceModalBaseStyles from global;

  min-height: 100vh;
  height: 100%;

  background-color: var(--matterColorF6);
  @media (--viewportMedium) {
    flex-basis: 720px;
    min-height: auto;
    height: auto;
  }
}
