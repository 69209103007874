.st0 {
  fill: #284c7b;
}
.st1 {
  clip-path: url(#SVGID_2_);
  fill: #ff0000;
}
.st2 {
  fill: #ffffff;
}
.st3 {
  fill: #d1d470;
}
.st4 {
  fill: #f5d652;
}
.st5 {
  fill: #c7cb2b;
}
.st6 {
  fill: #ebbd64;
}
.st7 {
  fill: #edd018;
}
.st8 {
  fill: #f1dd78;
}
.st9 {
  fill: #b1bb32;
}
.st10 {
  fill: #8ab14d;
}
.st11 {
  fill: #e7b037;
}
.st12 {
  fill: #b4c232;
}
.st13 {
  fill: #95b654;
}
.st14 {
  fill: #68a450;
}
.st15 {
  fill: #d8991f;
}
.st16 {
  fill: #a9c271;
}
.st17 {
  fill: #c7ce5a;
}
.st18 {
  fill: #5d9f4a;
}
.st19 {
  fill: #519249;
}
.st20 {
  fill: #95b75e;
}
.st21 {
  fill: #d1d57b;
}
.st22 {
  fill: #a6cce0;
}
.st23 {
  fill: #7fac46;
}
.st24 {
  fill: #529a44;
}
.st25 {
  fill: #78b8da;
}
.st26 {
  fill: #4f87c0;
}
.st27 {
  fill: #5da3ce;
}
.st28 {
  fill: #447dba;
}
.st29 {
  fill: #6894c8;
}
.st30 {
  fill: #8aa7d1;
}
.st31 {
  fill: #5179af;
}
.st32 {
  fill: #7d95c5;
}
.st33 {
  fill: #667fb8;
}
.st34 {
  fill: #3c67a3;
}
