@import '../../../styles/customMediaQueries.css';

/* PanelHeading subcomponent */
.mainTitle {
  composes: h3 from global;
  display: block;
  margin-bottom: 0;
}

.listingTitleMobile {
  margin: 30px 24px 0 24px;

  @media (--viewportLarge) {
    display: none;
  }
}

.transactionStatus {
  font-weight: var(--fontWeightBold);
  margin: 0;
}

/* BreakdownMaybe subcomponent */
.breakdownMaybe {
}

/* FeedSection subcomponent */
.feedHeading {
  color: var(--matterColorAnti);
  margin: 0 0 6px 0;
}

/* ActionButtons subcomponent */
.actionButtons {
}

.actionButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
}

.actionButton {
  padding: 2px 20px;
  width: auto;
  min-width: 40%;
}

.spinner {
  stroke: var(--marketplaceColor);
  stroke-width: 3px;
  height: 24px;
}

.sectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  margin: 16px 16px 0 16px;

  &:first-of-type {
    margin-left: 16px;

    @media (--viewportSmall) {
      margin-left: 32px;
    }

    @media (--viewportMedium) {
      margin-left: 64px;
    }

    @media (--viewportLarge) {
      margin-left: 20px;
    }
  }

  @media (--viewportSmall) {
    margin: 20px 32px 0 32px;
  }

  @media (--viewportMedium) {
    margin: 20px 64px 0 64px;
  }

  @media (--viewportLarge) {
    flex: 0 0 490px;
    margin: 30px 20px 0 0;
  }
}

.listingCardDesktop {
  display: none;
  @media (--viewportLarge) {
    display: inline-flex;
  }
}

.listingCardMobile {
  @media (--viewportLarge) {
    display: none;
  }
}
.mobileActionButtonSpacer {
  width: 100%;
  height: 120px;
  @media (--viewportLarge) {
    display: none;
  }
}

.sectionWrapperDetails {
  composes: sectionWrapper;

  order: -1;

  @media (--viewportLarge) {
    order: 1;
  }
}

.section {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 20px;

  background: var(--matterColorEE);
  border-radius: var(--borderRadius20);
  padding: 30px 20px;
}

.sectionDetails {
  composes: section;
  background: var(--matterColorLight);
  box-shadow: var(--boxShadowButton);
}

.detailsTitle {
  margin: 0;
  font-weight: var(--fontWeightBold);
}

.emphasizedText {
  color: var(--marketplaceColor);
}
