@import '../../styles/customMediaQueries.css';

.root {
  background-color: transparent;
  color: var(--matterColorDark);

  font-weight: var(--fontWeightRegular);
  padding: 6px 12px;

  &:hover {
    background-color: var(--marketplaceColorLight);
    box-shadow: none;
  }
}

.smallButton {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 40px;

  background-color: var(--matterColorLight);
  color: var(--matterColorDark);
  padding: 6px;

  & svg {
    width: 30px;
    height: 30px;
  }
}

.icon {
  margin-left: 12px;
}

.smallIcon {
  margin: 0;
}

.modalContainer {
  composes: marketplaceModalBaseStyles from global;

  min-height: 100vh;
  height: 100%;

  background-color: var(--matterColorBright);

  @media (--viewportMedium) {
    flex-basis: 320px;
    min-height: auto;
    height: auto;
    padding: 34px 36px;
  }
}

.shareItems {
  margin-bottom: 0;

  & li {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: var(--matterColor);
    }
  }

  & .shareButton,
  & .copyLink {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;

    cursor: pointer;

    &:hover {
      color: var(--matterColor);
    }
  }
}

.iconWreapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 34px;
  height: 34px;

  background-color: var(--marketplaceColor);
  border-radius: 50%;
}

.modalIcon {
  width: 24px;
  height: auto;
  fill: var(--matterColorLight);
  position: relative;
}

.modalContent {
  margin-top: 16px;
}
