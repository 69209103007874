@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  flex: 1;

  padding: 0;
  margin: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
    margin: 0 auto;
    width: 100%;
    max-width: calc(1290px + 36px + 36px);
  }

  @media (--viewportXLarge) {
    padding: 0;
    max-width: 1290px;
  }
}

.title {
  composes: h2 from global;

  font-weight: var(--fontWeightBold);
  letter-spacing: -0.44px;

  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  max-width: 1056px;
  width: 100%;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightRegular);
  }
}

.carousel {
  justify-content: space-between;

  @media (--viewportLarge) {
    display: flex;
  }
}

.carouselContent {
  & > div > div {
    justify-content: flex-start;
  }

  @media (--viewportLarge) {
    padding: 4px;
    overflow-x: hidden;

    flex-basis: 1056px;
    flex-grow: 0;

    & > * {
      flex-shrink: 0;
      margin: 0;
    }
  }
}

.carouselColumn {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 80%;

  & > * {
    margin: 0 6px;
  }

  @media (--viewportLarge) {
    & > * {
      margin: 0;
      width: 100%;
      /*height is equal 50% minus (first-child) margin-bottom half */
      height: calc(50% - 15px);
    }

    & > :first-child {
      margin-bottom: 30px;
    }
  }
}
