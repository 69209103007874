@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 11px !important;
}

.content {
  display: flex;
  flex-direction: column;

  flex: 1;

  padding: 0;
  margin: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
    margin: 0 auto;
    width: 100%;
    /* 1056 + paddings */
    max-width: calc(1056px + 72px);
  }

  @media (--viewportXLarge) {
    padding: 0;
    max-width: 1290px;
  }
}

.title {
  composes: h2 from global;

  font-weight: var(--fontWeightBold);
  margin-top: 0;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightRegular);
  }
}

.card {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  border-radius: var(--borderRadius20);

  color: var(--matterColorLight);
  background: url('./images/where-do-you-fit-2.png');
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),
    url('./images/where-do-you-fit-2.png');
  background-position: center;
  background-size: cover;
}

.cardTitle {
  font-weight: var(--fontWeightBold);
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 32px;

  @media (--viewportMedium) {
    font-size: 28px;
  }
}

.cardText {
  composes: h3 from global;

  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: var(--fontWeightRegular);

  @media (--viewportSmall) {
    font-size: 14px;
  }

  @media (--viewportMedium) {
    font-size: 16px;
  }

  @media (--viewportLarge) {
    max-width: 66%;
  }
}

.cta {
  composes: buttonPrimary from global;

  align-self: flex-start;
  width: auto;
  margin-top: 18px;
}
