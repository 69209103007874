.root {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  & > *:first-child:not(:last-child) {
    margin-right: 8px;
  }
}

.activityIcon {
  height: 100%;
  min-width: 35px;
  width: 100%;
  fill: var(--marketplaceColor);
}

.activityIconWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  padding: 8px;

  border-radius: 50%;
  background-color: var(--matterColorF6);
}
