@import '../../styles/customMediaQueries.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: auto;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 24px 12px;
    background-color: var(--matterColorLight);
    box-shadow: var(--boxShadowButton);
    border-radius: var(--borderRadius20);

    margin-top: 0;
    margin-bottom: 0;
  }
}

.author {
  width: 100%;
  composes: h4 from global;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.row {
  margin: 72px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 12px 24px;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-shrink: 0;
  padding: 0;
  color: var(--marketplaceColor);
}

.perUnit {
  margin: 0 6px 0 0;
  font-weight: var(--fontWeightBold);
  text-transform: lowercase;
}

.priceValue {
  /* Font */
  composes: h2 from global;

  margin: 0;
  font-weight: var(--fontWeightBold);
}

.bookingHelp {
  composes: h5 from global;

  margin-top: 0;
  margin-bottom: 0;
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* Mobile phones introduce bottom-bar, which takes 102px vertical space.
     In addition to that we have fixed buttons that take 228px vertical space.
  */
  margin: 0 0 330px 0;

  @media (--viewportMedium) {
    margin: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);
  margin-top: auto;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.contactUserButton {
  margin-right: 12px;
}

.closedListingButton {
  width: 100%;
  text-align: center;
}

.error {
  color: var(--colorFail);
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noMarginBottom {
  margin-bottom: 0;
}

.bookingUnavailable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 12px;

  & > button {
    margin-right: 0;
    margin-top: 12px;
  }
}
