@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBaseStyles from global;
}

.modalContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;

  color: var(--matterColor);
}
