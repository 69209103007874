@import '../../styles/customMediaQueries.css';

.tooltipTrigger {
  width: 100%;
  height: 100%;
}

.tooltip {
  padding: 0 !important;
  opacity: 1 !important;
  background-color: transparent !important;
}

.bookingsPreview {
  @media (--viewportMedium) {
    min-width: 278px;
  }
}
