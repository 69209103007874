@import '../../styles/customMediaQueries.css';

.root {
  position: fixed;
  bottom: 0;

  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  padding-left: 18px;

  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLightTop);

  @media (--viewportMedium) {
    height: var(--topbarHeightDesktop);
    padding: 0 12px 0 24px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.leftSide {
  flex: 1 0 70px;
  display: flex;

  @media (--viewportMedium) {
    display: none;
  }
}

.rightSide {
  display: flex;
  flex: 1 0 70px;
  justify-content: flex-end;

  @media (--viewportMedium) {
    display: none;
  }
}

.marginRight {
  margin-right: 6px;
}

.menuPofile {
  position: static;
}

.notificationDot {
  /* Dimensions */
  width: 14px;
  height: 14px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 8%;
  right: 18%;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.notificationDotMobileMenu {
  composes: notificationDot;
  top: -10px;
  right: 3px;
  position: relative;
}

.logoutButton {
  outline: none;
  border: 0;
  padding: 0;
  cursor: pointer;
}

/* Profile menu */
.profileMenuLabel {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;
  position: relative;
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 8px 0;
}

.marketplaceColorAvatar {
  background-color: var(--matterColorLight);

  & > svg path {
    fill: var(--marketplaceColor);
  }
}

.profileMenuContent {
  padding: 24px 0;
  margin-top: 0;
  bottom: var(--topbarHeight);
  width: 100vw;

  border-radius: var(--borderRadius20) var(--borderRadius20) 0 0;
  box-shadow: var(--boxShadowLightTop);

  @media (--viewportMedium) {
    bottom: var(--topbarHeightDesktop);
  }
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 15%;
  right: 0px;
  height: 70%;
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  composes: h4 from global;

  position: relative;
  display: block;

  white-space: nowrap;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColorDark);
  text-align: right;
  letter-spacing: -0.25px;
  font-weight: var(--fontWeightRegular);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.currentPage {
  color: var(--marketplaceColor);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--marketplaceColor);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--marketplaceColor);
    }
  }
}

.itemGap {
  margin-top: 20px;
}

.buttonWrapper {
  display: flex;

  justify-content: center;
  flex: 0 1 auto;
  padding-right: 6px;

  @media (--viewportMedium) {
    flex: 1;
    padding-right: 12px;
  }
}

.addActivityButton {
  composes: buttonPrimary from global;

  white-space: nowrap;
  display: block;
  width: auto;
  flex-basis: 122px;

  @media (--viewportLarge) {
    display: none;
  }
}

.hideLink {
  display: none;
}

.hideOnTablet {
  @media (--viewportMedium) {
    display: none;
  }
}

.hideNav {
  display: none;
}
