.root {
  &:hover {
    text-decoration: none;
  }
}

.card {
  display: flex;
  align-items: center;
  gap: 16px;
  text-decoration: none;

  width: 100%;
  min-height: 75px;
  padding: 10px 16px;

  border-radius: var(--borderRadius20);

  background-color: var(--matterColorLight);
  transition: all 0.2s;

  &:hover {
    text-decoration: none;
    transform: scale(1.01);
    box-shadow: var(--boxShadowListingCard);
  }

  margin-bottom: 20px;
}

.greyedOut {
  filter: grayscale(100%);
  opacity: 0.7;

  & > div {
    background-color: var(--colorGrey200);
  }
}

.noReadMessage {
  background-color: var(--marketplaceColorLight);
}

.info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  line-height: 16px;
}

.userName {
  color: var(--matterColorDark);
  font-weight: var(--fontWeightBold);
  margin-bottom: 6px;
}

.messageText {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;

  font-weight: var(--fontWeightRegular);
  color: var(--matterColorDark);
}

.messageTime {
  font-size: 14px;
  color: var(--matterColor);
  font-weight: var(--fontWeightRegular);
}

.avatar {
  flex-shrink: 0;
  height: 46px;
  width: 46px;

  background-color: var(--matterColorLight);

  & > svg path {
    fill: var(--marketplaceColor);
  }
}
