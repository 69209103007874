.emphasisedText {
  composes: h3 from global;

  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);
  text-transform: capitalize;
}

.blackText {
  composes: h3 from global;

  font-weight: var(--fontWeightBold);
  color: var(--matterColorDark);
  margin: 0;
}
