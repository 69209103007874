@import '../../styles/customMediaQueries.css';

.avatarBase {
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: inherit;
  background-color: var(--marketplaceColor);

  &:hover {
    text-decoration: none;

      background-color: var(--matterColorLight); /* Changes background color on hover */
      & > svg path {
        fill: var(--marketplaceColorDark); /* Changes SVG path fill color on hover */
      }
  }
}

/* Small Avatar */

.root {
  composes: avatarBase;

  /* Layout */
  width: 40px;
  height: 40px;
}

.avatarImage {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.smallAvatar {
  composes: avatarBase;

  /* Fixed dimensions */
  width: 32px;
  height: 32px;
}
.initialsSmall {
  /* Exception and unique font-size for the small avatar */
  font-size: 11px;
  font-weight: var(--fontWeightBold);
}

/* Medium Avatar */

.mediumAvatar {
  composes: avatarBase;

  /* Fixed dimensions */
  width: 60px;
  height: 60px;
}

/* Large Avatar */

.largeAvatar {
  composes: avatarBase;
  padding: 0;

  /* Fixed dimensions */
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.bannedUserIcon {
  width: 100%;
  height: 100%;
}
