@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  align-items: center;

  width: 150px;
  height: 58px;

  border-radius: var(--borderRadiusMedium);
  box-shadow: var(--boxShadowButton);

  background-color: var(--matterColorLight);

  & > svg {
    width: 100%;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
}

/* An image wrapper that defines the images aspect ratio 1:1 */
.aspectWrapper {
  padding-bottom: calc(3 / 13 * 100%);
}

.logo {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
