@import '../../styles/customMediaQueries.css';

.actionBar {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  padding: 20px;
  margin: 22px 24px;

  border-radius: var(--borderRadius20);
  box-shadow: var(--boxShadowButton);
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-around;

    margin: 22px 0 0;
  }
}

.editButton {
  composes: buttonSecondary from global;
}

.actionBarBtn {
  flex: 1;
  min-width: 150px;

  /* 5px half of a gap */
  @media (--viewportSmall) {
    min-width: calc(50% - 5px);
  }

  @media (--viewportMedium) {
    flex: 0;
    min-width: 150px;
  }
}

.actionBarPublishBtn {
  color: var(--colorSuccess);

  &:hover,
  &:focus {
    color: var(--matterColorLight);
    background-color: var(--colorSuccess);
  }
}

.approveColor {
  color: var(--marketplaceColor);
}

.deleteColor {
  color: var(--colorFail);
}

.actionBarDeleteBtn {
  composes: deleteColor;

  &:hover,
  &:focus {
    background-color: var(--colorFail);
  }
}

.message {
  flex: 1 0 100%;
}
