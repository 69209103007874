.root {
  fill: var(--matterColorLight);
}

.empty {
  fill: var(--marketplaceColorLight);
}

.filled {
  fill: var(--marketplaceColor);
}
