@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  composes: h4 from global;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 4px 0;
}

.bookingBreakdownSection {
  padding-bottom: 4px;
  margin-bottom: 14px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.itemBookedSeats {
  position: relative;
  text-transform: lowercase;

  &:hover > .bookingsPreview {
    display: block;
  }
}

.bookingsPreview {
  position: absolute;
  display: none;
  right: 0;
  bottom: 100%;

  @media (--viewportMedium) {
    min-width: 278px;
  }
}

.list {
  margin: 0;
  padding: 0;
}

.listItem {
  &:before {
    content: '-';
    margin-right: 6px;
    color: var(--matterColor);
  }
}

.itemLabelSession {
  color: var(--matterColor);
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bookingPeriodSectionRight {
  display: flex;
  flex-wrap: wrap;
  color: var(--matterColor);
}

.dayInfo {
  margin-left: 4px;
}

.subTotalLineItem {
  composes: h4 from global;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 7px 0 1px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  composes: h4 from global;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-top: 7px;
    padding-bottom: 1px;
  }
}

.itemLabel {
  composes: marketplaceSmallFontStyles from global;
  flex: 1;
  display: inline-flex;
  text-align: left;
}

.itemValue {
  composes: marketplaceSmallFontStyles from global;
  margin: 0 0 0 10px;
  text-align: right;
  max-width: 320px;

  @media (--viewportMedium) {
    max-width: 460px;
  }

  @media (--viewportLarge) {
    max-width: 240px;
  }
}

.itemGray {
  color: var(--matterColor);
}

.divider {
  width: 100%;
  height: 1px;
  border: none;
  background-color: var(--matterColorNegative);
}

.totalDivider {
  composes: divider;

  margin: 12px 0 5px 0;

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.totalLabel {
  composes: marketplaceSmallFontStyles from global;

  font-weight: var(--fontWeightBold);
  font-size: 18px;
}

.totalPrice {
  font-weight: var(--fontWeightBold);
  letter-spacing: -0.2px;
  margin: 0 0 0 10px;
  padding-top: 0px;
  font-size: 18px;
  line-height: 32px;
}

.detailsNote {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
}

.savingsNote {
  color: var(--marketplaceColor);
  margin: 0;
}

.feeTooltip {
  margin-top: 0 !important;
}

.breakdownTitle {
  font-weight: var(--fontWeightBold);
  margin: 0 0 10px 0;
}
