@import '../../styles/customMediaQueries.css';

/* 404 page */

.root {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  margin: 42px 24px;

  @media (--viewportMedium) {
    margin: 120px auto;
  }
}

.number {
  composes: h1 from global;

  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);
  margin: 0;
}

.heading {
  composes: h1 from global;

  font-weight: var(--fontWeightBold);
  text-align: center;
  margin: 0;
}

.description {
  composes: marketplaceSmallFontStyles from global;

  font-weight: var(--fontWeightRegular);
  text-align: center;
  margin: 0;

  max-width: 512px;
}

.searchForm {
  width: 100%;
  margin-top: 24px;
}
