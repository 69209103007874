@import '../../styles/customMediaQueries.css';

.fieldWithImage {
  position: relative;

  flex: 0 0 calc(50% - 8px);
  @media (--viewportSmall) {
    flex-basis: calc(33% - 9px);
  }
  @media (--viewportMedium) {
    flex-basis: calc(25% - 12px);
  }
}

.input {
  display: none;
}

.visibleInput {
  display: block;
  width: auto;
  position: absolute;
  top: 16px;
  right: 16px;
}

.root {
  display: block;
  min-width: 110px;

  padding: 8px 24px;
  margin: 0;
  border-radius: var(--borderRadius20);
  box-shadow: var(--boxShadowButton);

  transition: color 0.1s ease-out, background-color 0.1s ease-out;

  &:hover {
    background-color: var(--marketplaceColorLight);
  }
}

.vertical {
  flex-direction: column;
}

.horizontal {
  flex-direction: row;
  padding: 16px;
}

.iconVertical {
  margin-bottom: 8px;
}

.iconHorizontal {
  margin-right: 8px;
}

.selected {
  color: var(--matterColorLight);
  background-color: var(--marketplaceColor);

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.disabled {
  color: var(--matterColorLight);
  background-color: var(--matterColorNegative);
  box-shadow: none;

  &:hover {
    cursor: not-allowed;
  }
}

.root,
.labelText,
.titleText {
  &:hover {
    cursor: pointer;
  }

  &.disabled {
    color: var(--matterColorNegative);

    &:hover {
      cursor: default;
    }
  }
}

.titleText {
  line-height: 24px;
  font-weight: var(--fontWeightBold);
  text-align: center;

  padding: 0;
  margin: 0;
}

.imageLabel {
  padding: 8px 8px 32px 8px;
  height: 200px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--borderRadius20);
    margin-bottom: 6px;
  }

  & > label {
    font-size: 12px;
    margin-left: 2px;
    opacity: 0.8;
  }
}
