.root {
  display: flex;
  align-items: center;
}

.ratingCount {
  margin: 0 0 0 6px;

  & > span {
    color: var(--matterColorNegative);
  }
}

.ratingCountAccented {
  & > span {
    &:first-of-type {
      color: var(--matterColorDark);
    }

    &:last-of-type {
      color: var(--matterColor70);
    }
  }
}

.ratingCountNoStars {
  margin: 0;
}
