.root {
}

.label {
  display: flex;
}

.input {
  border-color: var(--colorAttention);
}

.inputSuccess {
  border-color: var(--colorSuccess);
}

.inputError {
  border-color: var(--colorFail);
}

.textarea {
  box-sizing: border-box;
  min-height: 110px;
  max-height: 200px;
}
