@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  align-items: center;
  background-color: var(--matterColorLight);
  color: var(--matterColorDark);
  padding: 12px;
}

.installApp {
  position: fixed;
  bottom: var(--topbarHeight);
  left: 0;
  right: 0;

  z-index: calc(var(--zIndexTopbar) - 1);
  box-shadow: var(--boxShadow);

  & > svg,
  & > img {
    min-width: 24px;
    width: 24px;
    fill: var(--marketplaceColor);
    margin-right: 12px;
  }

  @media (--viewportMedium) {
    border-radius: 4px;
    right: unset;
    bottom: unset;
    top: var(--topbarHeightDesktop);
    box-shadow: var(--boxShadowPopup);
  }
}

.installAppInline {
  justify-content: space-between;
}

.installAppTitle {
  margin-right: 34px;
}

.installAppTitleBig {
  composes: buttonPrimary from global;

  width: auto;
  margin: 0;
}

.installNote {
  color: var(--colorGrey500);
}

.closeButton {
  border: 0;
  padding: 6px;
  outline: 0;
  cursor: pointer;
  margin-left: auto;

  & > svg {
    min-width: 12px;
    min-height: 12px;
    height: 12px;
    width: 12px;
  }
}
