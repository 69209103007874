@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.sideBarError {
  composes: h4 from global;

  color: var(--colorFail);
  margin: 12px 0;
}

.seats {
  margin: 0 12px 12px;

  & > div:first-of-type {
    margin-bottom: 12px;
  }
}

.seatField {
  justify-content: space-between;
  flex: 1;
}

.additionalPersonCharge {
  line-height: 24px;
  margin-left: 12px;
  margin-right: 12px;
}

.datesToggle {
  border: 1px solid var(--matterColorEE);
  margin-right: 12px;
  margin-left: 12px;
  box-shadow: none;
  margin-bottom: 0;
  text-align: left;

  & > span {
    font-size: 12px;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  & div[class*='datesToggleContent'] {
    font-size: 12px;
  }

  @media (--viewportSmall) {
    & > span {
      font-size: 14px;
    }

    & div[class*='datesToggleContent'] {
      font-size: 14px;
    }
  }

  @media (--viewportMedium) {
    margin-right: 0;
    margin-left: 0;
  }
}

.priceBreakdownContainer {
  padding: 0 12px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
  color: var(--marketplaceColor);

  /* Remove the paddings frrom all of the line items */
  & > div {
    padding: 0;
  }
}

.error {
  color: var(--colorFail);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.smallPrint {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  text-align: center;
  margin: 20px 24px;
  flex-shrink: 0;
  font-size: 12px;

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  margin-top: auto;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.or {
  text-align: center;
  margin: 12px 0;
  font-size: 12px;
}
