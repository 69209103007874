@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

.rowList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  box-shadow: var(--boxShadowButton);
  border-radius: var(--borderRadius20);
  overflow: hidden;
}

.item {
  display: inline-flex;
  flex-grow: 1;
}

.firstInRowItemSelectedMobile {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  @media (--viewportMedium) {
    border-radius: var(--borderRadius20);
  }
}

.lastInRowItemSelectedMobile {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  @media (--viewportMedium) {
    border-radius: var(--borderRadius20);
  }
}

.leftOfItemSelected {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rightOfItemSelected {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
