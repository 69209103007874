.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > label {
    margin: 0;
  }
}

.openItem {
  margin-bottom: 10px;
}

.removeItem {
  padding: 6px 12px;
  border: 0;
  border-radius: var(--borderRadius20);
  outline: none;
  cursor: pointer;

  &:hover:not(:disabled) {
    background-color: var(--matterColorEE);
  }
}

.removeItemHidden {
  visibility: hidden;
}

.removeItemSpinner {
  width: 20px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addButton {
  text-align: right;
  padding: 8px 12px 8px 24px;
  border-radius: var(--borderRadius20);
  box-shadow: var(--boxShadowButton);

  font-weight: var(--fontWeightRegular);

  padding-right: 24px;
  margin: 0;
  margin-bottom: 10px;

  &:hover,
  &:focus {
    color: var(--marketplaceColor);
    background-color: var(--matterColorEE);
  }
}

.plus {
  font-size: 38px;
  font-weight: var(--fontWeightRegular);
}

.error {
  color: var(--colorFail);
}
