@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  flex: 1;

  padding: 0;
  margin: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
    margin: 0 auto;
    width: 100%;
    max-width: calc(1290px + 36px + 36px);
  }

  @media (--viewportXLarge) {
    padding: 0;
    max-width: 1290px;
  }
}

.country {
  &:first-of-type {
    & .title {
      margin-top: 0;
    }
  }

  &:not(:first-of-type) {
    & .title {
      margin-top: 30px;
    }
  }
}

.title {
  composes: h4 from global;

  font-weight: var(--fontWeightBold);
  letter-spacing: -0.44px;

  margin: 12px auto 20px;
  padding: 0;
  max-width: 1056px;
  width: 100%;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightRegular);
  }
}

.locations {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1056px;
  gap: 4px;

  & > li {
    font-size: 12px;
    margin: 0;
    padding: 0;
  }

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
