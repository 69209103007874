.root {
  display: flex;
  align-items: center;
}

.field {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.labelContainer {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  padding-right: 12px;

  & > label {
    margin-bottom: 0;
    font-weight: var(--fontWeightRegular);
  }
}

.description {
  composes: marketplaceTinyFontStyles from global;

  color: var(--matterColorCA);
  font-size: 12px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.incrementerContainer {
  display: flex;
  align-items: center;
}

.additionalIcon {
  margin-right: 12px;
}

.input {
  width: 38px;
  height: 100%;
  text-align: center;
  padding: 0;
  appearance: textfield;
  color: var(--matterColorDark);
  border: none;

  &:disabled {
    background: none;
    color: var(--matterColorDark);
  }

  /*Hide arrows from input number*/
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.button {
  composes: buttonPrimary from global;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;
  padding: 6px;
  margin: 0 4px;

  color: var(--matterColor);
  font-size: 20px;
  background-color: var(--matterColorLight);

  border: 1px solid var(--matterColorEE);
  border-radius: 50%;

  &:hover {
    border-color: var(--matterColor);
    background-color: white;
  }

  &:focus {
    border-color: var(--matterColor);
    background-color: var(--matterColorLight);
  }

  &:disabled {
    border: none;
    background-color: var(--matterColorEE);
  }

  &:disabled:hover {
    background-color: var(--matterColorEE);
  }
}

.errorMessage {
  margin: 0;
  margin-left: 10px;
}
