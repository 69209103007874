@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  margin-top: auto;
  /* Mobile phones introduce bottom-bar, for which we need to give 96px vertical space */
  margin-bottom: 96px;
}

.previewListingButton {
  composes: buttonSecondary from global;

  width: auto;
  margin: 24px 0;

  @media (--viewportMedium) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 100px;
  }
}

.submitButton {
  width: auto;
  margin: 24px 0;

  @media (--viewportMedium) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 100px;
  }
}

.marginLeft {
  margin-left: auto;
}

.arrowIcon {
  margin-left: 14px;
  height: 30px;
  width: 16px;

  @media (--viewportMedium) {
    margin-left: 30px;
  }
}
