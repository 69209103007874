.root {
  width: 100%;
}

.label {
  display: flex;
}

.select {
  composes: marketplaceInputStyles from global;

  color: var(--matterColorAnti);
  border-color: var(--colorAttention);
  padding-right: 20px;

  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }

  &:disabled {
    border-color: var(--matterColorNegative);
    color: var(--matterColorNegative);
    cursor: default;
    opacity: 1;
  }
}

.selectSuccess {
  color: var(--matterColorDark);
  border-color: var(--colorSuccess);
}

.selectError {
  border-color: var(--colorFail);
}
