@import '../../styles/customMediaQueries.css';

.root {
  display: inline-flex;
  flex-direction: column;

  user-select: none;

  max-width: 200px;
  border-radius: var(--borderRadius20);
  background-color: var(--matterColorLight);

  &:hover {
    box-shadow: var(--boxShadowButton);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    max-width: 234px;
  }

  @media (--viewportLarge) {
    height: 100%;
  }
}

.horizonalCard {
  flex-direction: row;
  max-width: 100%;
}

.aspectWrapper {
  padding-bottom: 100%;
}

.imageWrapper {
  position: relative;
  min-width: 100%;
  flex: 0 0 auto;
}

.imageWrapperHorizontal {
  min-width: 40%;
  max-width: 40%;
  flex: 0 0 40%;
}

.rootForImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadius20);
}

.heartButton {
  all: unset;
  position: absolute;
  top: 16px;
  right: 16px;

  &:focus,
  &:hover,
  &:disabled {
    background: transparent;
    box-shadow: none;
  }
}

.infoWrapper {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1;
  flex-shrink: 0;

  padding: 14px;
}

.activityName {
  font-weight: var(--fontWeightBold);
  color: var(--matterColorDark);
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 6px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (--viewportMedium) {
    font-size: 16px;
    margin: 8px 0 6px;
  }
}

.userType {
  color: var(--matterColor70);
}

.activityInfoText {
  font-weight: var(--fontWeightBold);
  color: var(--matterColorDark);
  font-size: 14px;
  line-height: 24px;
  margin-top: 6px;

  @media (--viewportMedium) {
    font-size: 14px;
  }
}

.activeText {
  color: var(--marketplaceColor);
}

.activityPlaceName {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;

  color: var(--matterColor70);
  margin: 0 0 6px;
  overflow-wrap: break-word;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 22px;
    margin: 8px 0 6px;
  }
}

.duration {
  color: var(--matterColorDark);
}

.price {
  color: var(--matterColorDark);
  font-size: 14px;
  line-height: 24px;
  text-align: left;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}
