@import '../../styles/customMediaQueries.css';

.tooltip {
  line-height: 20px;
  max-width: 70vw;

  @media (--viewportMedium) {
    max-width: 368px;
  }
}

.tooltipTrigger {
  margin-left: 2px;
  margin-top: -6px;
}
