@import '../../styles/customMediaQueries.css';

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.disabled {
  opacity: 0.5;
}

.sectionContainer {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
}

.formRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.subTitle {
  margin-top: 0;
}

.radioButtonRow {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 12px;
  white-space: nowrap;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.radioButtonRow > :first-child {
  margin-right: 36px;
}

.selectCountry {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
  margin-bottom: 12px;
  text-align: right;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.bankDetailsStripeField p {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;

  margin-top: 6px;
  margin-bottom: 0;
}

.missingStripeKey {
  color: var(--colorFail);
}

.accountInformationTitle {
  margin: 0;
  padding-bottom: 16px;
}

.savedCountry {
  font-size: 16px;
  color: var(--matterColor);
  line-height: initial;

  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-bottom: 26px;
  }
}

.formMargin {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.savedInformation {
  margin-bottom: 32px;
}

.savedBankAccount {
  text-align: left;
  text-decoration: none;
  color: var(--matterColorDark);
  border-color: var(--colorSuccess);

  &:hover {
    text-decoration: none;
  }
}

.bottomWrapper {
  margin-top: 46px;
  margin-bottom: 96px;

  @media (--viewportMedium) {
    margin-top: 96px;
  }
}

.submitButton {
  width: auto;
  min-width: 150px;

  margin-left: auto;
}

.commission {
  display: flex;
}
