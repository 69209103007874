@import '../../styles/customMediaQueries.css';

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.whiteBg {
  background-color: var(--matterColorLight);
}

.addCertificateInfo {
  display: inline-block;
  font-size: 12px;
  line-height: initial;
  color: var(--matterColor);
  margin: 0;
}

.uploadedFile {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: var(--fontWeightRegular);
  color: var(--marketplaceColor);
  max-height: 58px;

  padding: 8px 15px 8px 24px;
  margin-top: 12px;
  margin-bottom: 24px;

  border-radius: var(--borderRadius20);
  box-shadow: var(--boxShadowButton);

  @media (--viewportMedium) {
    max-height: 50px;
    margin-bottom: 32px;
  }
}

.removeItem {
  padding: 4px 10px;
  border: 0;
  border-radius: var(--borderRadius20);
  outline: none;
  cursor: pointer;

  &:hover:not(:disabled) {
    background-color: var(--matterColorEE);
  }
}

.submitButton {
  width: auto;
  min-width: 220px;
  margin: auto;
}
