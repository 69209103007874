@import '../../../styles/customMediaQueries.css';

.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

/* Container for side and content */
.container {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  min-width: 0;

  /* Layout */
  width: 100%;
  height: 100%;

  @media (--viewportLarge) {
    flex-direction: row;
    background-color: var(--matterColorF6);
  }
}

.main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;
  min-height: calc(100vh - (var(--topbarHeight) + 136px));

  padding: 10px 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    min-height: calc(100vh - (var(--topbarHeightDesktop) + 149px));
  }

  @media (--viewportLarge) {
    padding: 112px 36px 82px 60px;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 112px calc((100% - 1056px) / 2) 82px 60px;
  }
}

.sideNav {
  /* Layout */
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  padding: 0;
  padding-bottom: 20px;
  background-color: var(--matterColorF6);

  @media (--viewportLarge) {
    padding: 70px 36px 82px;
    overflow-x: auto;
    background-color: var(--matterColorF6);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 70px 36px 82px calc((100% - 1056px) / 2 + 10px);
  }
}

.tabs {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  /* Layout */
  width: 100%;
  height: 100%;

  @media (--viewportLarge) {
    background-color: var(--matterColorF6);
  }
}

.tab {
  margin-left: 16px;
  white-space: nowrap;

  text-align: center;
  flex: 1 0 150px;

  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
    flex-basis: 174px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;
    flex-basis: 174px;
  }

  @media (--viewportLarge) {
    flex-basis: 0;
    margin-left: 0;

    &:first-child {
      flex-basis: 0;
      padding-left: 0;
    }

    &:last-child {
      flex-basis: 0;
      padding-right: 0;
    }
  }
}

.navTabs {
  display: flex;
  flex-direction: row;
  position: relative;

  overflow-x: scroll;
  padding-bottom: 8px;

  @media (--viewportLarge) {
    flex-direction: column;
    overflow-x: auto;

    padding-bottom: 0;
  }
}
