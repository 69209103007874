@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.title {
  composes: h2 from global;

  font-weight: var(--fontWeightBold);
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.24px;

  margin-left: 24px;

  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.32px;

    margin-bottom: 22px;
  }

  @media (--viewportLarge) {
    margin-top: 0;
    margin-left: 0;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  composes: buttonPrimary from global;

  /* Font */
  composes: marketplaceTabNavFontStyles from global;

  text-decoration: none;

  font-weight: var(--fontWeightBold);
  color: var(--matterColorDark);
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowButton);

  line-height: 32px;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    background-color: var(--marketplaceColorLight);
    box-shadow: var(--boxShadowButton);
  }

  @media (--viewportMedium) {
    line-height: 32px;
  }

  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);

    margin-top: 0;
    margin-bottom: 20px;
  }
}

.selectedLink {
  color: var(--matterColorLight);
  background-color: var(--marketplaceColor);

  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
  }
}

.link.selectedLink {
  &:hover {
    text-decoration: none;
    color: var(--matterColorLight);
    background-color: var(--marketplaceColor);
  }
}

.disabled {
  pointer-events: none;
  text-decoration: none;

  color: var(--matterColorLight);
  background-color: var(--matterColorNegative);
  box-shadow: none;
}
