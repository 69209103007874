.root {
  & h3 {
    margin-top: 32px;
  }

  & p {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;

    font-weight: var(--fontWeightRegular);
    margin: 14px 0;
  }

  & ul {
    list-style: disc;
    margin-left: 44px;
  }

  & li {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;

    font-weight: var(--fontWeightRegular);
    margin: 14px 0;
  }
}
