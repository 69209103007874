.cellWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-decoration: line-through;
  color: var(--matterColor);
}

.hasBooking {
  color: var(--matterColorLight);
  text-decoration: none;
}

.hasSession {
  color: var(--mattercolorDark);
  text-decoration: none;
}

.disabled {
  color: var(--matterColor);
  text-decoration: none;
}
