.root {
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-right: 8px;
  }
}

.iconBackground {
  fill: var(--matterColorF6);
}
