.root {
  position: relative;
  display: inline-block;
  width: 100%;
}

.label {
  composes: buttonFilter from global;
  composes: marketplaceSearchFilterLabelFontStyles from global;
}

.labelHihglight {
  animation: backgroundAnimation 3s ease forwards;
}

@keyframes backgroundAnimation {
  0% {
    color: var(--marketplaceColorLight);
    background: var(--marketplaceColor);
    filter: brightness(0.9);
  }
  50% {
    background: var(--marketplaceColorDark);
    filter: brightness(0.95);
  }
  90% {
    color: var(--marketplaceColorLight);
    background: var(--marketplaceColor);
    filter: brightness(1);
  }
  100% {
    border-color: var(--marketplaceColor);
  }
}

.labelSelected {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  &:hover {
    background-color: var(--marketplaceColorDark);
    border: 1px solid var(--marketplaceColorDark);
  }
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  min-width: 300px;
  margin-top: 8px;
  background-color: var(--matterColorLight);

  /* Borders */
  box-shadow: var(--boxShadowPopup);
  border-radius: var(--marketplaceFilterBorderRadius);
  transition: var(--transitionStyleButton);
}

.popupSize {
  max-height: 320px;
  overflow-y: auto;
  padding: 16px 30px 0px 30px;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
