@import '../../styles/customMediaQueries.css';

.datesPicker {
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius20);
  height: 100%;
}

.datesToggle {
  flex-grow: 1;
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);
  margin-bottom: 24px;
  height: var(--topbarHeight);
  box-shadow: var(--boxShadowButton);

  padding: 0;
  border: 0;
  outline: none;
  text-align: left;

  padding: 10px 24px 8px;

  & > label {
    margin: 0;
    padding: 0;
  }

  @media (--viewportMedium) {
    display: none;
  }
}

.datesToggleContent {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--matterColorCA);
}

.datesToggleContentWithValue {
  color: var(--matterColorDark);
}

.datesToggleContentWithAvailability {
  display: flex;
  justify-content: space-between;
  overflow: visible;
}

.datesPickerButton {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  border: 0;
  border-radius: var(--borderRadius);

  padding: 14px 12px 4px 24px;

  height: 100%;
  width: 100%;
  cursor: pointer;
  text-align: left;

  & > label {
    margin: 0;
    padding: 0;
  }

  & > span {
    color: var(--matterColorCA);
    font-size: 16px;
  }

  &:hover {
    background-color: var(--matterColorEE);
    box-shadow: var(--boxShadowButton);
  }

  @media (--viewportMedium) {
    padding: 16px 24px 8px 24px;
  }
}

.datesPickerButtonSelected {
  box-shadow: var(--boxShadowButton);

  & > span {
    color: var(--matterColorCA);
  }
}

.datesPickerWithDatesSelected > span {
  color: var(--matterColorDark);
}

.modalContent {
  padding: 60px 0;
  flex-grow: 1;
}

.modalFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: var(--boxShadowTop);
  padding: 12px 24px;
  background-color: var(--matterColorLight);
}

.filterPopup {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  border-radius: var(--borderRadius20);
}

.filterPopupClickHandler {
  flex-grow: 1;
}

.datesToDisplay {
  flex-shrink: 0;
}

.availabilityInfo {
  position: relative;
  display: flex;
  text-align: right;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -8px;
    right: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: linear-gradient(90deg, var(--colorSuccess) 50%, var(--colorAttention) 50%);
  }

  &.availabilityInfoAvailable::after {
    color: var(--colorSuccess);
    background: var(--colorSuccess);
  }
  &.availabilityInfoOnRequest::after {
    color: var(--colorAttention);
    background: var(--colorAttention);
  }
}
