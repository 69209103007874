@import '../../styles/customMediaQueries.css';

.pickerSuccess {
  & :global(.DateInput_input) {
    border-color: var(--colorSuccess);
  }
}

.pickerError {
  & :global(.DateInput_input) {
    border-color: var(--colorFail);
  }
}

.labelDisabled {
  color: var(--matterColorNegative);
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.mobileMargins {
  width: calc(100% - 48px);
  margin: 0 24px 6px 24px;

  @media (--viewportMedium) {
    width: 100%;
    margin: 0 0 12px;
  }
}
